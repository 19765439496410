/**
 * @author:  jens@reach-u.com
 * @since: 2018-05-10
 */

import api from '../api';

/**
 * token-controller : Token Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/token45controller
 */

const token = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/token45controller/getAllTokensUsingGET
   */
  getAllTokens: () => api.get('/api/1/token'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/token45controller/createTokenUsingPOST
   */
  createToken: (clientId, data) =>
    api.post('/api/1/token/{clientId}'.replace(/{clientId}/, clientId), data),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/token45controller/removeTokenUsingDELETE
   */
  removeToken: tokenId => api.delete('/api/1/token/{tokenId}'.replace(/{tokenId}/, tokenId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/token45controller/getTokenByIdUsingGET
   */
  getTokenById: tokenId => api.get('/api/1/token/{tokenId}'.replace(/{tokenId}/, tokenId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/token45controller/updateTokenUsingPUT
   */
  updateToken: data => api.put('/api/1/token/{tokenId}'.replace(/{tokenId}/, data.id), data),
};

export default token;
