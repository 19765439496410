import BGM_PORTAL_BASEURL from '../../config/BGM_PORTAL_BASEURL';

const config = {
  /**
   * returns locally stored configuration at public/config/server.json changeable via environment variables
   */
  getApiConfig: async function() {
    let response;
    response = await fetch(BGM_PORTAL_BASEURL.concat('config/server.json'));
    return await response.json();
  },
};

export default config;
