/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2019-02-04
 */
import {setLoader} from './appLoader';
import api from '../../api';
import {setToastError, setToastInfo} from './toastMessage';
import {getEndMsOfCurrentMonth, getStartMsOfCurrentMonth} from '../../utils/date';
import {USER_TRIAL_PLAN} from './appUserTrialPlan';
import {fetchClientPricingPlan, setClientPricingPlan} from './clientPricingPlan';

//feature
export const CLIENT_TRIAL = '[Client trial plan]';

//types
export const FETCH_CLIENT_TRIAL = `${CLIENT_TRIAL} FETCH`;
export const SET_CLIENT_TRIAL = `${CLIENT_TRIAL} SET`;
export const START_CLIENT_TRIAL = `${CLIENT_TRIAL} START`;

//creators
export const fetchTrial = () => ({
  type: FETCH_CLIENT_TRIAL,
});
export const setTrial = data => ({
  type: SET_CLIENT_TRIAL,
  payload: data,
});
export const startTrial = () => ({
  type: START_CLIENT_TRIAL,
});

//thunks
export const fetchClientTrialPlan = clientId => {
  return async dispatch => {
    dispatch(fetchTrial());
    dispatch(setLoader(true, CLIENT_TRIAL));
    await api.pricingPlan
      .getClientTrial(clientId)
      .then(response => {
        dispatch(setTrial(response));
      })
      .catch(error => {
        if (error.status === 404) {
          // mock API response until BE is implemented
          dispatch(setTrial({endDate: null, available: true}));
          dispatch(
            setToastInfo(
              {message: 'API responded with 404, showing mock data for Trial'},
              USER_TRIAL_PLAN
            )
          );
        } else {
          dispatch(setToastError({message: error.message}, CLIENT_TRIAL));
        }
      });
    dispatch(setLoader(false, CLIENT_TRIAL));
  };
};

export const startClientTrialPlan = clientId => {
  return async dispatch => {
    dispatch(startTrial());
    dispatch(setLoader(true, CLIENT_TRIAL));
    await api.pricingPlan
      .startClientTrial(clientId)
      .then(response => {
        dispatch(setTrial(response));
        dispatch(fetchClientPricingPlan(clientId));
      })
      .catch(error => {
        if (error.status === 404) {
          // mock API response until BE is implemented
          const today = new Date();
          dispatch(
            setTrial({
              endDate: new Date(today.setDate(today.getDate() + 14)).toUTCString(),
              available: true,
            })
          );
          dispatch(
            setClientPricingPlan({
              id: 999,
              keys: null,
              name: 'Trial',
              optIn: false,
              pointCount: 0,
              pointLimit: null,
              pointPrice: 0,
              price: 0,
              strategy: null,
              support: false,
              periodStart: getStartMsOfCurrentMonth(),
              periodEnd: getEndMsOfCurrentMonth(),
              activationDate: new Date().getTime(),
            })
          );
          dispatch(
            setToastInfo(
              {message: 'API responded with 404, showing mock data for Trial'},
              USER_TRIAL_PLAN
            )
          );
        } else {
          dispatch(setToastError({message: error.message}, CLIENT_TRIAL));
        }
      });
    dispatch(setLoader(false, CLIENT_TRIAL));
  };
};
