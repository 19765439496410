/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Icon} from 'semantic-ui-react';
import {MESSAGE_AREA_HEIGHT} from '../../config/LAYOUT';
import styled from 'styled-components';

export const Message = styled.div`
  height: ${MESSAGE_AREA_HEIGHT}px;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
`;

const MessageContent = styled.p`
  border-radius: 0 0 3px 3px;
  font-size: 16px;
  background: var(--secondary-yellow-20perc);
  padding: 0 32px 0 32px;
  display: inline-block;
  line-height: ${MESSAGE_AREA_HEIGHT}px;
  a {
    font-size: 16px;
    color: var(--primary-light);
  }
`;

const MessageArea = ({content, areaVisible, location: {pathname}}) => {
  const {iconName, iconColor, message, linkTo, linkText} = content;
  if (!message || !areaVisible || pathname.includes('statistics')) {
    return null;
  }

  return (
    <Message>
      <MessageContent>
        {!!iconName && <Icon color={iconColor} name={iconName} />} {message}
        {!!linkTo && <a href={linkTo}>{linkText}</a>}
      </MessageContent>
    </Message>
  );
};

MessageArea.defaultProps = {
  content: {
    iconName: '',
    iconColor: 'yellow',
    message: '',
    linkTo: '',
    linkText: '',
  },
  areaVisible: false,
};

MessageArea.propTypes = {
  content: PropTypes.shape({
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    message: PropTypes.string,
    linkTo: PropTypes.string,
    linkText: PropTypes.string,
  }),
  areaVisible: PropTypes.bool,
};

export default withRouter(
  connect(state => ({
    areaVisible: state.messageArea.areaVisible,
    content: state.messageArea.content,
  }))(MessageArea)
);
