import {clearStatistics} from './statistics';

/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

//feature
export const STATISTICS_FILTERS = '[Statistics filters]';

//types
export const SET_FILTERS = `${STATISTICS_FILTERS} SET`;
export const CLEAR_FILTERS = `${STATISTICS_FILTERS} CLEAR`;

//creators
export const setStatisticsFilters = filters => ({
  type: SET_FILTERS,
  payload: filters,
});
export const clearStatisticsFilters = () => ({
  type: CLEAR_FILTERS,
});

//thunks
export const updateFilters = (data, tableSize) => dispatch => {
  if (tableSize) {
    dispatch(setStatisticsFilters({data, tableSize}));
  } else {
    dispatch(setStatisticsFilters(data));
  }
  dispatch(clearStatistics());
};
