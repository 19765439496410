/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2019-02-04
 */

import {setLoader} from './appLoader';
import api from '../../api';
import {setToastError, setToastInfo} from './toastMessage';
import {refreshAppUserClientPricingPlan, setUserPricingPlan} from './appUserPricingPlan';
import {getEndMsOfCurrentMonth, getStartMsOfCurrentMonth} from '../../utils/date';
import {addMessageAreaContent} from './messageArea';
import {trialIsActive} from '../../config/MESSAGE_AREA_MESSAGES';
import moment from 'moment';

//feature
export const USER_TRIAL_PLAN = '[User trial plan]';

//types
export const FETCH_USER_TRIAL = `${USER_TRIAL_PLAN} FETCH`;
export const SET_USER_TRIAL = `${USER_TRIAL_PLAN} SET`;
export const START_USER_TRIAL = `${USER_TRIAL_PLAN} START`;

//creators
export const fetchTrial = () => ({
  type: FETCH_USER_TRIAL,
});
export const setTrial = data => ({
  type: SET_USER_TRIAL,
  payload: data,
});
export const startTrial = () => ({
  type: START_USER_TRIAL,
});

//thunks
export const fetchUserTrialPlan = () => {
  return async dispatch => {
    dispatch(fetchTrial());
    dispatch(setLoader(true, USER_TRIAL_PLAN));
    await api.pricingPlan
      .getTrial()
      .then(response => {
        dispatch(setTrial(response));
        if (response.endDate) {
          const daysRemaining = moment
            .duration(moment(response.endDate).diff(moment()))
            .as('days')
            .toFixed(0);
          if (daysRemaining > 0) {
            dispatch(
              addMessageAreaContent(
                trialIsActive.content(daysRemaining),
                trialIsActive.priority,
                USER_TRIAL_PLAN
              )
            );
          }
        }
      })
      .catch(error => {
        if (error.status === 404) {
          // mock API response until BE is implemented
          dispatch(
            setTrial({
              endDate: null,
              available: true,
            })
          );
          dispatch(
            setToastInfo(
              {message: 'API responded with 404, showing mock data for Trial'},
              USER_TRIAL_PLAN
            )
          );
        } else {
          dispatch(setToastError({message: error.message}, USER_TRIAL_PLAN));
        }
      });
    dispatch(setLoader(false, USER_TRIAL_PLAN));
  };
};

export const startUserTrialPlan = () => {
  return async dispatch => {
    dispatch(startTrial());
    dispatch(setLoader(true, USER_TRIAL_PLAN));
    await api.pricingPlan
      .startTrial()
      .then(response => {
        dispatch(setTrial(response));
        dispatch(refreshAppUserClientPricingPlan());
        if (response.endDate) {
          const daysRemaining = Math.abs(
            moment.duration(moment(response.endDate).diff(moment())).as('days')
          ).toFixed(0);
          dispatch(
            addMessageAreaContent(
              trialIsActive.content(daysRemaining),
              trialIsActive.priority,
              USER_TRIAL_PLAN
            )
          );
        }
      })
      .catch(error => {
        if (error.status === 404) {
          // mock API response until BE is implemented
          const today = new Date();
          dispatch(
            setTrial({
              endDate: new Date(today.setDate(today.getDate() + 14)).toUTCString(),
              available: true,
            })
          );
          dispatch(
            setUserPricingPlan({
              id: 999,
              keys: null,
              name: 'Trial',
              optIn: false,
              pointCount: 0,
              pointLimit: null,
              pointPrice: 0,
              price: 0,
              strategy: null,
              support: false,
              periodStart: getStartMsOfCurrentMonth(),
              periodEnd: getEndMsOfCurrentMonth(),
              activationDate: new Date().getTime(),
            })
          );
          dispatch(
            addMessageAreaContent(
              trialIsActive.content(14),
              trialIsActive.priority,
              USER_TRIAL_PLAN
            )
          );
          dispatch(
            setToastInfo(
              {message: 'API responded with 404, showing mock data for Trial'},
              USER_TRIAL_PLAN
            )
          );
        } else {
          dispatch(setToastError({message: error.message}, USER_TRIAL_PLAN));
        }
      });
    dispatch(setLoader(false, USER_TRIAL_PLAN));
  };
};
