import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {HEADER_HEIGHT, MAX_WIDTH_1439} from '../../../config/LAYOUT';
import Menu from 'semantic-ui-react/dist/es/collections/Menu/Menu';
import {NavLink} from 'react-router-dom';
import {Button} from '../../elements/semanticElements';

const Outer = styled.div`
  display: none;
  background: var(--text-white);
  z-index: 2;
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  bottom: 0;
  left: 0;
  right: 0;

  @media (${MAX_WIDTH_1439}) {
    display: ${({open}) => (open ? 'block' : 'none')};
  }
`;

const Inner = styled.div`
  background: var(--rgba-secondary-blue-10perc);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-around;
`;

const LinkItem = styled.div`
  margin-bottom: 24px;
  position: relative;
`;

const Content = styled.div`
  margin-top: 48px;
  width: 250px;
`;

const Box = styled.div`
  width: 10px;
`;

const UserAction = styled.div`
  margin-top: 40px;

  p {
    font-size: 14px;
    color: var(--secondary-blue);
    margin: 0 0 16px;
  }
`;

export const DropNav = ({isOpen, routes, onLogout, onLinkClick, username}) => {
  if (!isOpen) return null;

  return (
    <Outer open={isOpen}>
      <Inner>
        <Content>
          {routes.map(route => (
            <LinkItem key={route.path}>
              <Menu.Item as={NavLink} to={route.path} onClick={onLinkClick}>
                {route.name}
              </Menu.Item>
            </LinkItem>
          ))}
          <UserAction>
            <p>{username}</p>
            <Button ghost onClick={onLogout}>
              Logout
            </Button>
          </UserAction>
        </Content>
        <Box />
      </Inner>
    </Outer>
  );
};

DropNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  onLogout: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};
