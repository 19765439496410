import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {NavLink, withRouter, Link} from 'react-router-dom';
import {Menu} from 'semantic-ui-react';
import {appUserSignOut} from '../../../store/actions/appUser';
import {getAppRoutesByAuthorities} from '../AppRoutes';
import {HEADER_HEIGHT, MAX_WIDTH_1439} from '../../../config/LAYOUT';
import {DropNav} from './DropNav';
import {Button} from '../../elements/semanticElements';

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  background: var(--rgba-secondary-blue-10perc);
`;

const HeaderMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: none;
  align-items: center;
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;

  @media (${MAX_WIDTH_1439}) {
    display: none;
  }
`;

const StyledNavlink = styled(NavLink)`
  font-size: 16px;

  &.active:after {
    display: block;
    content: url('/images/triangle_round_corners_header.svg');
    position: absolute;
    left: calc(50% - 8px);
    bottom: -20px;
  }
`;

const LinkItem = styled.div`
  margin-right: 48px;
  font-size: 16px;
  position: relative;
`;

const UsernameSegment = styled.div`
  p {
    font-size: 14px;
    color: var(--secondary-blue);
    margin-right: 18px;
    display: inline;
  }

  @media (${MAX_WIDTH_1439}) {
    display: none;
  }
`;

const MenuButton = styled.button`
  display: none;
  padding: 0 8px 0 0;
  position: relative;
  align-self: flex-end;
  border: none;
  background: none;
  color: var(--text-black);
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  cursor: pointer;

  @media (${MAX_WIDTH_1439}) {
    display: block;
  }
`;

const Cross = styled.span`
  &:before,
  &:after {
    position: absolute;
    right: -6px;
    top: 2px;
    content: ' ';
    height: 15px;
    width: ${({open}) => (open ? '2px' : '3px')};
    background-color: var(--primary-light);
  }
  &:before {
    transform: ${({open}) => (open ? 'rotate(45deg)' : 'none')};
  }
  &:after {
    transform: ${({open}) => (open ? 'rotate(-45deg)' : 'none')};
  }
`;

class AppHeader extends React.Component {
  state = {
    id: null,
    authorities: [],
    menuLinks: [],
    menuOpen: false,
    appRoutes: [],
  };

  handleClick = () => {
    const {appUserSignOut, history} = this.props;
    appUserSignOut().then(() => history.push('/'));
  };

  toggleMenu = () => {
    const {menuOpen} = this.state;
    this.setState({menuOpen: !menuOpen});
    document.activeElement.blur();
  };

  render() {
    const {menuLinks, menuOpen, appRoutes} = this.state;
    const {id, username} = this.props;

    if (!id) {
      return null;
    }

    return (
      <MenuContainer>
        <HeaderMenu>
          <Link to="/billing/status">
            <img width="180" src="/images/logo.svg" alt="GeoDataHub logo" />
          </Link>

          <NavLinks>{menuLinks}</NavLinks>
          <MenuButton onClick={this.toggleMenu}>
            {menuOpen ? 'Close' : 'Menu'} <Cross open={menuOpen} />
          </MenuButton>

          <UsernameSegment>
            <p>{username}</p>
            <Button ghost onClick={this.handleClick}>
              Logout
            </Button>
          </UsernameSegment>
        </HeaderMenu>
        <DropNav
          routes={appRoutes}
          onLinkClick={() => this.setState({menuOpen: false})}
          onLogout={this.handleClick}
          isOpen={menuOpen}
          username={username}
        />
      </MenuContainer>
    );
  }

  static getDerivedStateFromProps(props, state) {
    const {id, authorities} = props;
    if (id !== state.id || authorities !== state.authorities) {
      const appRoutes = getAppRoutesByAuthorities(authorities).filter(
        route => route.path && route.name
      );
      return {
        id,
        authorities,
        menuLinks: id
          ? appRoutes.map(route => (
              <LinkItem key={route.path}>
                <Menu.Item as={StyledNavlink} to={route.path}>
                  {route.name}
                </Menu.Item>
              </LinkItem>
            ))
          : [],
        appRoutes: appRoutes,
      };
    }
    return null;
  }
}

AppHeader.propTypes = {
  id: PropTypes.number,
  username: PropTypes.string,
  authorities: PropTypes.array,
  appUserSignOut: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    state => ({
      id: state.appUser.id,
      username: state.appUser.username,
      authorities: state.appUser.authorities,
    }),
    {
      appUserSignOut,
    }
  )(AppHeader)
);
