import {SET_LOADER} from '../actions/appLoader';

/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-22
 */

const initialState = {
  counter: 0,
  isVisible: false,
};

export default function appLoader(state = initialState, action = {}) {
  switch (true) {
    case action.type.includes(SET_LOADER):
      return {...state, isVisible: action.payload};
    default:
      return state;
  }
}
