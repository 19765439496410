import api from '../../api';
import {setToastError} from './toastMessage';

//feature
export const CONFIG = '[Config]';

//types
export const FETCH_CONFIG = `${CONFIG} FETCH`;
export const SET_CONFIG = `${CONFIG} SET`;

//creators
export const configFetch = () => ({
  type: FETCH_CONFIG,
});
export const setConfig = config => ({
  type: SET_CONFIG,
  payload: config,
});

export const getConfig = () => {
  return async dispatch => {
    dispatch(configFetch());
    await api.config
      .getApiConfig()
      .then(response => {
        dispatch(setConfig(response));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
  };
};
