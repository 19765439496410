/*
 *  @author kaspar.arme@reach-u.com
 *  @since 2019-05-07
 */

import styled from 'styled-components';
import {FULL_WIDTH_CONTENT_1600px, MIN_WIDTH_FOR_CONTENT_480px} from '../../config/LAYOUT';

export const Content = styled.div`
  max-width: ${FULL_WIDTH_CONTENT_1600px};
  min-width: ${MIN_WIDTH_FOR_CONTENT_480px};
  margin: 0 auto;
  text-align: center;
`;

export const LogoDiv = styled.div`
  padding-left: 80px;
  display: flex;
  img {
    margin: 20px 0;
  }
`;

export const Card = styled.div`
  justify-self: center;
  border-radius: 3px;
  background: var(--rgba-secondary-blue-10perc);
  padding: 40px 12px 42px 48px;
  width: 400px;

  &.active {
    box-shadow: var(--card-box-shadow);
  }

  h2 {
    margin-bottom: 24px;
  }

  .input-field {
    margin-left: 0;
  }

  .input-field .input-error {
    margin: 10px 0 0 16px;
  }

  form {
    display: flex;
    flex-direction: column;
  }
`;
