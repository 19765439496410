import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const VENDOR_DETAILS = '[Vendor details]';

//types
export const FETCH_VENDOR_DETAILS = `${VENDOR_DETAILS} FETCH`;
export const SET_VENDOR_DETAILS = `${VENDOR_DETAILS} SET`;
export const CLEAR_VENDOR_DETAILS = `${VENDOR_DETAILS} CLEAR`;

//creators
export const fetchVendorDetails = () => ({
  type: FETCH_VENDOR_DETAILS,
});
export const setVendorDetails = data => ({
  type: SET_VENDOR_DETAILS,
  payload: data,
});
export const clearVendorDetails = () => ({
  type: CLEAR_VENDOR_DETAILS,
});

//thunks
export const fetchVendorDetailsData = tokenId => {
  return async dispatch => {
    dispatch(fetchVendorDetails());
    dispatch(setLoader(true, VENDOR_DETAILS));
    await api.vendors
      .findVendorsByServiceTokenId(tokenId)
      .then(response => dispatch(setVendorDetails(response)))
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, VENDOR_DETAILS));
  };
};
