/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-22
 */

import api from '../../api';
import {isValidEmail} from '../../utils/validation';
import {setFormLoader} from './formLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import errorMessages from '../../config/ERROR_MESSAGES';
import {replaceNullValuesWithEmptyStrings} from '../../utils/utils';
import {handleFieldErrors} from '../../utils/error';

//feature
export const CLIENT = '[Client]';

//types
export const FETCH_CLIENT_DETAILS = `${CLIENT} FETCH`;
export const FETCH_CLIENT_SUSPENSION_REASON = `${CLIENT} FETCH_SUSPENSION_REASON`;
export const SET_CLIENT_DETAILS = `${CLIENT} SET`;
export const CLEAR_CLIENT_DETAILS = `${CLIENT} CLEAR`;
export const SET_CLIENT_EMAIL = `${CLIENT} SET EMAIL`;
export const SET_CLIENT_AUTHORITIES = `${CLIENT} SET AUTHORITIES`;
export const CANCEL_CLIENT_ACCOUNT = `${CLIENT} DELETE`;
export const REINSTATE_CLIENT_ACCOUNT = `${CLIENT} REINSTATE`;
export const SUSPEND_CLIENT = `${CLIENT} SUSPEND`;
export const UNSUSPEND_CLIENT = `${CLIENT} UNSUSPEND`;
export const CHANGE_CLIENT_OWNER = `${CLIENT} CHANGE_OWNER`;

//creators
export const clientDetailsFetch = () => ({
  type: FETCH_CLIENT_DETAILS,
});
export const clientSuspensionReasonFetch = () => ({
  type: FETCH_CLIENT_SUSPENSION_REASON,
});
export const setClientDetails = details => ({
  type: SET_CLIENT_DETAILS,
  payload: details,
});
export const clearClientDetails = () => ({
  type: CLEAR_CLIENT_DETAILS,
});
export const setClientEmail = email => ({
  type: SET_CLIENT_EMAIL,
  payload: email,
});
export const setClientAuthorities = authorities => ({
  type: SET_CLIENT_AUTHORITIES,
  payload: authorities,
});
export const setClientAsSuspended = reason => ({
  type: SUSPEND_CLIENT,
  payload: reason,
});
export const setClientAsReactivated = () => ({
  type: UNSUSPEND_CLIENT,
});
export const setClientOwner = owner => ({
  type: CHANGE_CLIENT_OWNER,
  payload: owner,
});

export const putClientInDeletionQueue = () => ({type: CANCEL_CLIENT_ACCOUNT});
export const removeClientFromDeletionQueue = () => ({type: REINSTATE_CLIENT_ACCOUNT});

//thunks
export const fetchClientDetails = clientId => {
  return async dispatch => {
    dispatch(clientDetailsFetch());
    dispatch(setFormLoader(true, CLIENT));
    await api.clients
      .getClient(clientId)
      .then(response => {
        dispatch(setClientDetails(response));
      })
      .catch(error => {
        dispatch(clearClientDetails());
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
  };
};

export const updateClientData = clientData => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    const {
      id,
      name,
      address,
      registerCode,
      status,
      contactPersonName,
      contactPersonPhone,
      billingEmail,
      suspensionReason,
      vatNumber,
    } = clientData;

    await api.clients
      .updateClient(
        replaceNullValuesWithEmptyStrings({
          id,
          name,
          address,
          registerCode,
          status,
          contactPersonName,
          contactPersonPhone,
          billingEmail,
          suspensionReason,
          vatNumber,
        })
      )
      .then(response => {
        dispatch(setClientDetails(response));
        dispatch(setToastSuccess({message: 'Changes saved'}, CLIENT));
      })
      .catch(error => {
        dispatch(setToastError({message: handleFieldErrors(error)}, CLIENT));
      });

    dispatch(setFormLoader(false, CLIENT));
  };
};

export const updateClientUsername = (clientId, username) => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    const {
      inputErrors: {missingEmail, invalidEmail},
    } = errorMessages;
    let errorMessage = null;
    if (!username) {
      errorMessage = missingEmail;
    } else if (!isValidEmail(username)) {
      errorMessage = invalidEmail;
    }
    if (errorMessage) {
      dispatch(setToastError({message: errorMessage}, CLIENT));
    } else {
      await api.user
        .changeUserUsername(clientId, username)
        .then(response => {
          dispatch(setClientEmail(response.username));
          dispatch(setToastSuccess({message: 'Changes saved'}, CLIENT));
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, CLIENT));
        });
    }
    dispatch(setFormLoader(false, CLIENT));
  };
};

export const updateClientAuthorities = (userId, authorities) => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    await api.user
      .changeUserAuthorities(userId, authorities)
      .then(response => {
        dispatch(setClientAuthorities(response.authorities));
        dispatch(setToastSuccess({message: 'Changes saved'}, CLIENT));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
  };
};

export const createClient = clientData => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    const {name, email} = clientData;
    const {
      inputErrors: {missingClient},
    } = errorMessages;
    let clientId = null;
    if (!name) {
      dispatch(setToastError({message: missingClient}, CLIENT));
      dispatch(setFormLoader(false, CLIENT));
      return clientId;
    }
    await api.clients
      .createClient({name, user: {username: email}})
      .then(response => {
        clientId = response.id;
        setTimeout(() => dispatch(setToastSuccess({message: 'Client created'}, CLIENT)), 100);
      })
      .catch(error => {
        dispatch(setToastError({message: handleFieldErrors(error)}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
    return clientId;
  };
};

export const deleteClient = clientId => {
  return async dispatch => {
    let result = false;
    dispatch(setFormLoader(true, CLIENT));
    await api.clients
      .deleteClient(clientId)
      .then(() => {
        dispatch(clearClientDetails());
        setTimeout(() => dispatch(setToastSuccess({message: 'Client deleted'}, CLIENT)), 100);
        result = true;
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
    return result;
  };
};

export const setClientToBeDeletedStatus = (toBeDeleted, id) => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));

    if (toBeDeleted) {
      await api.clients
        .setClientToBeDeletedTrue(id)
        .then(() => {
          dispatch(putClientInDeletionQueue());
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, CLIENT));
        });
    } else {
      await api.clients
        .setClientToBeDeletedFalse(id)
        .then(() => {
          dispatch(removeClientFromDeletionQueue());
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, CLIENT));
        });
    }

    dispatch(setFormLoader(false, CLIENT));
  };
};

export const suspendClient = (clientId, reason) => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    await api.clients
      .suspendClient(clientId, reason)
      .then(() => {
        dispatch(setClientAsSuspended(reason));
        setTimeout(() => dispatch(setToastSuccess({message: 'Client suspended'}, CLIENT)), 100);
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
    return reason;
  };
};

export const unSuspendClient = (clientId, reason) => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    await api.clients
      .reinstateClient(clientId, reason)
      .then(() => {
        dispatch(setClientAsReactivated());
        setTimeout(() => dispatch(setToastSuccess({message: 'Client reinstated'}, CLIENT)), 100);
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
    return reason;
  };
};

export const fetchClientSuspensionReason = clientId => {
  return async dispatch => {
    dispatch(clientSuspensionReasonFetch());
    dispatch(setFormLoader(true, CLIENT));
    await api.clients
      .getClientSuspensionReason(clientId)
      .then(response => {
        dispatch(setClientAsSuspended(response[0].suspensionReason));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
  };
};

export const changeClientOwner = (clientId, owner) => {
  return async dispatch => {
    dispatch(setFormLoader(true, CLIENT));
    await api.clients
      .changeClientOwner(clientId, owner)
      .then(() => {
        dispatch(setClientOwner(owner));
        setTimeout(() => dispatch(setToastSuccess({message: 'Client owner changed'}, CLIENT)), 100);
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT));
      });
    dispatch(setFormLoader(false, CLIENT));
    return owner;
  };
};
