/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import {
  SET_CLIENT_PRICING_PLAN_PENDING,
  CLEAR_CLIENT_PENDING_PLAN,
} from '../actions/clientPricingPlanPending';
import {RESET_ALL} from '../constants';

const initialState = {};

export default function clientPricingPlanPending(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CLIENT_PRICING_PLAN_PENDING:
      return action.payload;
    case CLEAR_CLIENT_PENDING_PLAN:
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
