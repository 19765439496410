/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-05-31
 */

import api from '../api';

/**
 * statistics controller: Service Usage Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/service45usage45controller
 */

const statistics = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/service45usage45controller
   * return array of all made requests which pass the filter
   */
  getStatistics: (authority, filter) =>
    api.post(`/api/1/service_usage/${authority}/metrics`, filter),
};

export default statistics;
