/**
 * @author:  jens@reach-u.com
 * @since: 2018-05-29
 */

import api from '../api';

const services = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/services45controller/getAllApisUsingGET
   * returns array of all services
   */
  getApiServiceList: () =>
    api.get('/api/1/services').then(response =>
      response.map(item => {
        return {...item, status: null};
      })
    ),

  getApiServiceDetails: serviceId =>
    api.get('/api/1/services').then(response => {
      const service = response.find(item => item.id.toString() === serviceId.toString());
      return {...service, status: null};
    }),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/services45controller/getAllGroupsUsingGET
   * returns array of all service groups
   */
  getApiServiceGroupList: () => api.get('/api/1/service/groups'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/services45controller/getAllClientsByServiceIdUsingGET
   * returns array of clients
   */
  getAllClientsByServiceId: async serviceId =>
    api.get('/api/1/service/{serviceId}'.replace(/{serviceId}/, serviceId)).then(response =>
      response.map(client => {
        return {...client, email: client.users[0].username};
      })
    ),
};

export default services;
