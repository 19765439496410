/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-09
 */

import api from '../api';

/**
 * auth-controller : Auth Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/auth45controller
 */

const auth = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/getUserByTokenUsingGET
   * ADMIN & USER - returns user object by Authorization Header
   */
  getUserByToken: () => api.get('/api/1/auth'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/getAuthorityOptionsUsingGET
   * ADMIN & USER returns list of available authorities
   */
  getAuthorityOptions: () => api.get('/api/1/auth/authorities'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/getSessionClientUsingGET
   * ADMIN & USER - returns client object by Authorization Header
   */
  getSessionClient: () => api.get('/api/1/auth/client'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/resetUserPasswordUsingPOST
   * ADMIN & USER - Sends e-mail to user with password reset link
   */
  resetUserPassword: email => api.post('/api/1/auth/password-reset', {username: email}),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/resetUserPasswordUsingPOST
   * ADMIN & USER - Set new password for user
   */
  confirmPasswordReset: (resetLinkToken, password) =>
    api.put('/api/1/auth/password-reset', {token: resetLinkToken, password}),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/invalidateTokenUsingDELETE
   * ADMIN & USER - invalidate Authorization Header token
   */
  invalidateToken: () => api.delete('/api/1/auth/token'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/generateTokenUsingPOST
   * ADMIN & USER - returns object with token for given username/password
   */
  generateToken: (username, password) => api.post('/api/1/auth/token', {username, password}),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/verifyUserUsingPUT
   * ADMIN & USER - verifies users e-mail address by link sent to his inbox
   */
  verifyUser: verifyLinkToken => api.put('/api/1/auth/verify', {token: verifyLinkToken}),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/auth45controller/resendVerificationTokenUsingPOST
   */
  resendVerificationToken: id => api.post(`/api/1/auth/resend-token/${id}`),
};

export default auth;
