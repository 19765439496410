/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-11
 */
import {RESET_ALL} from '../constants';
import {SET_INVOICE_LIST, CLEAR_INVOICE_LIST} from '../actions/invoiceList';

const initialState = [
  /*
  {
    "id":0,
    "clientId":0,
    "filename": "string",
    "created": 1528732181274,
    "clientName": "string",
    "number": "string",
    "status": "PAID",
    "total": 0,
    "records": [
      {
        "amount": 0,
        "description": "string"
      }
    ]
  },
  ...
  */
];

export default function invoiceList(state = initialState, action = {}) {
  switch (action.type) {
    case SET_INVOICE_LIST: {
      return action.payload;
    }
    case CLEAR_INVOICE_LIST:
    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}
