/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-15
 */

import {RESET_ALL} from '../constants';
import {SET_USER_PRICING_PLAN, CLEAR_USER_PRICING_PLAN} from '../actions/appUserPricingPlan';

const initialState = {
  id: null,
  name: null,
  strategy: null,
  description: null,
  price: null,
  pointPrice: null,
  pointLimit: null,
  pointCount: null,
  periodStart: null,
  periodEnd: null,
  optIn: null,
  pointsPerDay: null,
};

export default function appUserClientPricingPlan(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_PRICING_PLAN: {
      return action.payload;
    }
    case CLEAR_USER_PRICING_PLAN:
    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}
