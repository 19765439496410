/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const element = document.querySelector('.app-loader');

class AppLoader extends React.Component {
  render() {
    return null;
  }

  componentDidUpdate(prevProps) {
    const {isVisible} = this.props;
    if (isVisible !== prevProps.isVisible) {
      element.className = 'app-loader' + (isVisible ? ' app-loader-visible' : '');
    }
  }
}

AppLoader.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isVisible: state.appLoader.isVisible,
}))(AppLoader);
