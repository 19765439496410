/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import {setLoader} from './appLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import api from '../../api';

//feature
export const USER_PRICING_PLAN_PENDING = '[User pending pricing plan]';

//types
export const FETCH_USER_PENDING_PLAN = `${USER_PRICING_PLAN_PENDING} FETCH`;
export const SET_USER_PENDING_PLAN = `${USER_PRICING_PLAN_PENDING} SET`;
export const CANCEL_USER_PENDING_PLAN = `${USER_PRICING_PLAN_PENDING} CANCEL`;
export const CLEAR_USER_PENDING_PLAN = `${USER_PRICING_PLAN_PENDING} CLEAR`;

//creators
export const _fetchUserPendingPlan = () => ({
  type: FETCH_USER_PENDING_PLAN,
});
export const setUserPendingPlan = pendingPlan => ({
  type: SET_USER_PENDING_PLAN,
  payload: pendingPlan,
});
export const cancelUserPendingPlan = () => ({
  type: CANCEL_USER_PENDING_PLAN,
});
export const clearUserPendingPlan = () => ({
  type: CLEAR_USER_PENDING_PLAN,
});

//thunks
export const fetchAppUserClientPricingPlanPending = () => {
  return async dispatch => {
    dispatch(_fetchUserPendingPlan());
    dispatch(setLoader(true, USER_PRICING_PLAN_PENDING));
    await api.pricingPlan
      .findPending()
      .then(payload => {
        dispatch(
          setUserPendingPlan({...payload, activationDate: Date.parse(payload.activationDate)})
        );
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_PRICING_PLAN_PENDING));
      });
    dispatch(setLoader(false, USER_PRICING_PLAN_PENDING));
  };
};

export const cancelAppUserClientPricingPlanPending = () => {
  return async dispatch => {
    dispatch(cancelUserPendingPlan());
    dispatch(setLoader(true, USER_PRICING_PLAN_PENDING));
    await api.pricingPlan
      .cancelPending()
      .then(() => {
        dispatch(clearUserPendingPlan());
        dispatch(setToastSuccess({message: 'Pending plan cancelled'}, USER_PRICING_PLAN_PENDING));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_PRICING_PLAN_PENDING));
      });
    dispatch(setLoader(false, USER_PRICING_PLAN_PENDING));
  };
};
