const warningColor = 'yellow';

export const accountFrozen = {
  content: {
    iconName: 'warning sign',
    iconColor: warningColor,
    message: 'Your account has been suspended. You can not manage or use API services.',
  },
  priority: 999,
};

export const userUnverified = {
  content: {
    iconName: 'warning sign',
    iconColor: warningColor,
    message: 'Your account status is "Unverified" and therefore your account usage is limited. ',
    linkTo: '/settings/change-email',
    linkText: 'Verify account',
  },
  priority: 899,
};

export const incompleteBillingInfo = {
  content: {
    iconName: 'warning sign',
    iconColor: warningColor,
    message: 'You have not given us your complete billing information! Please refer to ',
    linkTo: '/settings/change-billing-info',
    linkText: 'Account settings',
  },
  priority: 799,
};

export const pointLimitWillBeExceeded = {
  content: {
    iconName: 'warning sign',
    iconColor: warningColor,
    message:
      'At your current usage rate you will reach your request limit before the end of billing period! ',
    linkTo: '/billing/plans',
    linkText: 'Update pricing plan',
  },
  priority: 699,
};

export const trialIsActive = {
  content: remaining => ({
    iconName: 'warning sign',
    iconColor: warningColor,
    message: `You are using trial period for the next ${remaining} days`,
  }),
  priority: 900,
};
