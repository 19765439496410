//mainly App and general layout
export const FOOTER_HEIGHT = 160;
export const MESSAGE_AREA_HEIGHT = 52;
export const HEADER_HEIGHT = 80;
export const MAIN_HEADING_MARGIN = 56;
export const MAX_WIDTH_1439 = 'max-width: 1439px';
export const MIN_WIDTH_FOR_CONTENT_480px = '480px';
export const FULL_WIDTH_CONTENT_1600px = '1600px';
export const LIMITED_WIDTH_CONTENT_1400px = '1400px';
//limited width containers are inside full width container so the same will be used for them?
export const SCREEN_WIDTH_WHERE_FULL_CONTENT_IS_90perc = (1600 * 100) / 90 + 'px';

//media queries
export const MAX_WIDTH_1023 = 'max-width: 1023px';
