/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-02
 */

//types
export const UPDATE_MESSAGE_AREA = 'UPDATE MESSAGE';
export const SET_MESSAGE_AREA = 'SET MESSAGE';
export const CLEAR_MESSAGE_AREA = 'CLEAR & HIDE MESSAGE';

//creators
export const updateMessageArea = (data, feature) => ({
  type: `${feature} ${UPDATE_MESSAGE_AREA}`,
  payload: data,
  meta: {feature},
});
export const setMessageArea = (isVisible, feature) => ({
  type: `${feature} ${SET_MESSAGE_AREA} ${isVisible.toString().toUpperCase()}`,
  payload: isVisible,
  meta: {feature},
});
export const clearMessageArea = feature => ({
  type: `${feature} ${CLEAR_MESSAGE_AREA}`,
});

//thunks
export const addMessageAreaContent = (content, priority, feature) => (dispatch, getState) => {
  const {
    priority: messagePriority,
    content: footerContent,
    queue: messageQueue,
  } = getState().messageArea;
  if (messagePriority === 0) {
    dispatch(updateMessageArea({content, priority}, feature));
    dispatch(setMessageArea(true, feature));
  } else if (messagePriority > priority) {
    const queue = [...messageQueue];
    const isItemInQueue = queue.reduce((_check, item) => item.priority === priority, false);
    if (!isItemInQueue) {
      queue.push({content, priority});
      dispatch(updateMessageArea({queue}, feature));
    }
  } else if (messagePriority < priority) {
    const queue = [...messageQueue];
    queue.push({content: footerContent, priority: messagePriority});
    dispatch(updateMessageArea({content, priority, queue}, feature));
  }
};

export const removeFooterContent = (priority, feature) => (dispatch, getState) => {
  const {priority: messagePriority, queue: messageQueue} = getState().messageArea;
  if (priority === messagePriority) {
    if (messageQueue.length === 0) {
      dispatch(clearMessageArea(feature));
    } else {
      const queue = [...messageQueue];
      queue.sort((a, b) => b.priority - a.priority);
      const newContent = queue.shift();
      dispatch(updateMessageArea({...newContent, queue}, feature));
    }
  } else {
    dispatch(
      updateMessageArea({queue: messageQueue.filter(item => item.priority !== priority)}, feature)
    );
  }
};
