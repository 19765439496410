/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-05-31
 */

import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const STATISTICS = '[Statistics]';

//types
export const FETCH_STATISTICS = `${STATISTICS} FETCH`;
export const SET_STATISTICS = `${STATISTICS} SET`;
export const CLEAR_STATISTICS = `${STATISTICS} CLEAR`;

//creators
export const _fetchStatistics = () => ({
  type: FETCH_STATISTICS,
});
export const setStatistics = statistics => ({
  type: SET_STATISTICS,
  payload: statistics,
});
export const clearStatistics = () => ({
  type: CLEAR_STATISTICS,
});

//thunks
export const fetchStatistics = (inputFilter, inputCompareFilter) => {
  const {getStatistics} = api.statistics;
  const filter = {...inputFilter};
  const compareFilter = {...inputCompareFilter};

  return async (dispatch, getState) => {
    dispatch(_fetchStatistics());
    dispatch(setLoader(true, STATISTICS));

    const isAdmin = getState().appUser.authorities.some(auth => auth.authority === 'ADMIN');
    const authority = isAdmin ? 'admin' : 'client';

    if (!isAdmin) {
      delete filter.clientIds;
      delete compareFilter.clientIds;
    }

    const stats = await getStatistics(authority, filter);
    const compareStats = await getStatistics(authority, compareFilter);

    Promise.all([stats, compareStats])
      .then(([response, compareResponse]) => {
        const {data, totalElements, totalPages} = response;
        dispatch(
          setStatistics({
            statistics: data,
            compareStatistics: compareResponse.data,
            totalResponseElements: totalElements,
            totalTablePages: totalPages,
          })
        );
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, STATISTICS));
  };
};
