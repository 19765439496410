/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import {SET_CLIENT_PRICING_PLAN} from '../actions/clientPricingPlan';
import {RESET_ALL} from '../constants';

const initialState = {};

export default function clientPricingPlan(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CLIENT_PRICING_PLAN:
      return action.payload;
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
