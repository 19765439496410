/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-09
 */

/**
 * https://fkhadra.github.io/react-toastify/
 * https://github.com/fkhadra/react-toastify
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {clearToast} from '../../store/actions/toastMessage';
import 'react-toastify/dist/ReactToastify.css';
import './toastMessage.css';
import {
  selectToastId,
  selectToastMessage,
  selectToastStatusCode,
  selectToastType,
} from '../../store/reducers/toastMessage';

class ToastMessageContainer extends React.Component {
  render() {
    const {type} = this.props;

    return (
      <ToastContainer
        type={type}
        position="top-right"
        className="toast-container"
        toastClassName={`toast-box ${type}`}
        autoClose={5000}
        draggable={false}
      />
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statusCode === 401) {
      // Ignore all messages after "Your session has been terminated..." message
      return;
    }
    if (prevProps.statusCode === 502) {
      // Ignore all messages after "Server is temporarily down" message
      return;
    }
    const {id} = this.props;
    if (id !== prevProps.id) {
      this.showMessage();
    }
  }

  showMessage = () => {
    const {message, type, statusCode} = this.props;
    if (message) {
      this.hideMessage();
      this.toastMessageId = toast(message, {type});
      if (statusCode === 401 || statusCode === 502) {
        return;
      }
      this.props.clearToast();
    }
  };

  hideMessage = () => {
    if (this.toastMessageId) {
      toast.dismiss(this.toastMessageId);
      delete this.toastMessageId;
    }
  };
}

ToastMessageContainer.defaultProps = {
  message: '',
  statusCode: 0,
  id: '',
};

ToastMessageContainer.propTypes = {
  message: PropTypes.string,
  statusCode: PropTypes.number,
  id: PropTypes.string,
  clearToast: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => {
  const feature = '[Toast container]';
  return {
    clearToast: () => dispatch(clearToast(feature)),
  };
};

export default withRouter(
  connect(
    state => ({
      message: selectToastMessage(state),
      statusCode: selectToastStatusCode(state),
      id: selectToastId(state),
      type: selectToastType(state),
    }),
    mapDispatchToProps
  )(ToastMessageContainer)
);
