/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-13
 */

import store from '../store';
import BGM_PORTAL_API_BASEURL from '../config/BGM_PORTAL_API_BASEURL';
import {getAuthorizationToken, unsetAuthorizationToken} from './authorizationToken';
import {clearUser} from '../store/actions/appUser';
import {clearUserClient} from '../store/actions/appUserClient';
import {clearToast, setToastError, setToastInfo} from '../store/actions/toastMessage';
import messages from '../config/TOAST_MESSAGES';
import {selectAuthHeaderNamespace} from '../store/reducers/config';

const feature = '[Api]';

const api = {
  fetch: async function(url, options) {
    if (BGM_PORTAL_API_BASEURL !== url.substr(0, BGM_PORTAL_API_BASEURL.length)) {
      url = BGM_PORTAL_API_BASEURL + url;
    }

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    let authToken = getAuthorizationToken();

    if (!!authToken) {
      headers[selectAuthHeaderNamespace(store.getState())] = authToken;
    }

    options = Object.assign(
      {},
      {
        method: 'get',
        headers: headers,
      },
      options || {}
    );

    options.method = options.method.toLowerCase();

    if ((options.method === 'post' || options.method === 'put') && options.body !== undefined) {
      options.body = JSON.stringify(options.body);
    }

    let request = await fetch(url, options);
    let response = null;
    let throwError = null;
    try {
      let contentType = request.headers.get('Content-Type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        response = await request.json();
      } else {
        response = await request.text();
      }
      switch (request.status) {
        case 200:
          break;
        case 502:
          throwError = response;
          store.dispatch(setToastError({message: messages.serverOffline}, feature));
          setTimeout(() => store.dispatch(clearToast()), 2000);
          break;
        case 401:
          /**
           * token is not valid anymore
           */
          throwError = response;
          unsetAuthorizationToken();
          store.dispatch(clearUser());
          store.dispatch(clearUserClient());
          store.dispatch(setToastInfo({message: messages.sessionTerminated}, feature));
          setTimeout(() => store.dispatch(clearToast()), 2000);
          break;
        case 403:
        case 404:
          throwError = {message: `Page not found - ${url}`};
          break;
        default:
          throwError = response || request.statusText;
          break;
      }
    } catch (error) {
      throwError = error;
    }

    if (throwError) {
      if (typeof throwError === 'string') {
        throwError = {message: throwError};
      }
      throw throwError;
    }

    return response;
  },

  get: function(url) {
    return this.fetch(url, {method: 'get'});
  },

  post: function(url, data) {
    return this.fetch(url, {method: 'post', body: data});
  },

  put: function(url, data) {
    return this.fetch(url, {method: 'put', body: data});
  },

  delete: function(url, data) {
    return this.fetch(url, {method: 'delete', body: data});
  },
};

export default api;
