/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-30
 */

import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const API_SERVICE = '[API service]';

//types
export const FETCH_SERVICE_DETAILS = `${API_SERVICE} FETCH`;
export const SET_SERVICE_DETAILS = `${API_SERVICE} SET`;
export const CLEAR_SERVICE_DETAILS = `${API_SERVICE} CLEAR`;

//creators
export const fetchServiceDetails = () => ({
  type: FETCH_SERVICE_DETAILS,
});
export const setServiceDetails = service => ({
  type: SET_SERVICE_DETAILS,
  payload: service,
});
export const clearServiceDetails = () => ({
  type: CLEAR_SERVICE_DETAILS,
});

//thunks
export const fetchApiServiceDetails = serviceId => {
  return async dispatch => {
    dispatch(fetchServiceDetails());
    dispatch(setLoader(true, API_SERVICE));
    let service = null;
    await api.services
      .getApiServiceDetails(serviceId)
      .then(response => (service = response))
      .catch(error => {
        dispatch(setToastError({message: error.message}, API_SERVICE));
      });
    if (service) {
      await api.services
        .getAllClientsByServiceId(service.id)
        .then(response => {
          service.clients = response;
          dispatch(setServiceDetails(service));
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, API_SERVICE));
        });
    }
    dispatch(setLoader(false, API_SERVICE));
  };
};
