import {CLEAR_VENDOR_DETAILS, SET_VENDOR_DETAILS} from '../actions/tokenVendorDetails';
import {RESET_ALL} from '../constants';

const initialState = [];

export default function vendorDetails(state = initialState, action = {}) {
  switch (action.type) {
    case SET_VENDOR_DETAILS:
      return action.payload;
    case CLEAR_VENDOR_DETAILS:
    case RESET_ALL: {
      return initialState;
    }
    default:
      return state;
  }
}
