/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const PRICING_PLAN_LIST = '[Pricing plan list]';

//types
export const FETCH_PRICING_PLAN_LIST = `${PRICING_PLAN_LIST} FETCH`;
export const SET_PRICING_PLAN_LIST = `${PRICING_PLAN_LIST} SET`;
export const CLEAR_PRICING_PLAN_LIST = `${PRICING_PLAN_LIST} CLEAR`;

//creators
export const _fetchPricingPlanList = () => ({
  type: FETCH_PRICING_PLAN_LIST,
});
export const setPricingPlanList = planList => ({
  type: SET_PRICING_PLAN_LIST,
  payload: planList,
});

//thunks
export const fetchPricingPlanList = () => {
  return async dispatch => {
    dispatch(_fetchPricingPlanList());
    dispatch(setLoader(true, PRICING_PLAN_LIST));
    await api.pricingPlan
      .findAll()
      .then(payload => {
        payload.sort(
          (a, b) => (a.strategy === 'FIXED_MONTH' ? a.price || 0 > b.price || 0 : Infinity)
        );
        dispatch(setPricingPlanList(payload));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, PRICING_PLAN_LIST));
  };
};
