/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-07
 */

import AppPageLoader from '../components/app/AppPageLoader';

/**
 *  {
 *    path: "/route-path",
 *    exact: true | undefined,
 *    component: AppPageLoader({component: () => import("../path-to-component")}),
 *    roles: ["GUEST", "USER", "ADMIN"],
 *    name: "Menu link" | undefined,
 *    key: "route-component-key" | undefined (if undefined, path is used as key instead)
 *  },
 *  {
 *    path: "/route-path",
 *    redirect: "/redirect-to-path",
 *    roles: ["GUEST", "USER", "ADMIN"]
 *  }
 */

const APP_ROUTES = [
  {
    path: '/',
    exact: true,
    redirect: '/billing/status',
    roles: ['USER', 'ADMIN'],
  },
  {
    path: '/sign-in',
    component: AppPageLoader({component: () => import('../components/pages/main/main')}),
    roles: ['GUEST'],
    name: 'Sign in',
    icon: 'sign in',
  },
  {
    path: '/sign-in',
    redirect: '/billing/status',
    roles: ['USER', 'ADMIN'],
  },
  {
    path: '/sign-up',
    component: AppPageLoader({component: () => import('../components/pages/main/main')}),
    roles: ['GUEST'],
    name: 'Create account',
    icon: 'signup',
  },
  {
    path: '/sign-up',
    redirect: '/billing/status',
    roles: ['USER', 'ADMIN'],
  },
  {
    path: '/EULA',
    exact: true,
    component: AppPageLoader({
      component: () => import('../components/pages/end-user-license-agreement'),
    }),
    roles: ['GUEST', 'USER', 'ADMIN'],
  },
  {
    path: '/verify-email/:verifyLinkToken',
    component: AppPageLoader({component: () => import('../components/pages/verifyEmail')}),
    roles: ['GUEST', 'USER', 'ADMIN'],
  },
  {
    path: '/password-recovery',
    component: AppPageLoader({component: () => import('../components/pages/passwordRecovery')}),
    roles: ['GUEST'],
  },
  {
    path: '/password-recovery',
    redirect: '/billing/status',
    roles: ['USER', 'ADMIN'],
  },
  {
    path: '/password-reset/:passwordResetToken',
    component: AppPageLoader({component: () => import('../components/pages/passwordReset')}),
    roles: ['GUEST', 'USER', 'ADMIN'],
  },
  {
    path: '/services',
    component: AppPageLoader({
      component: () => import('../components/pages/services/ServiceIndex'),
    }),
    roles: ['ADMIN'],
    name: 'Services',
  },
  {
    path: '/clients',
    component: AppPageLoader({component: () => import('../components/pages/clients/ClientsIndex')}),
    roles: ['ADMIN'],
    name: 'Clients',
  },
  {
    path: '/api-keys',
    component: AppPageLoader({component: () => import('../components/pages/api-keys/api-keys')}),
    roles: ['USER', 'ADMIN'],
    name: 'Keys',
  },
  {
    path: '/statistics',
    component: AppPageLoader({
      component: () => import('../components/pages/statistics/statisticsIndex'),
    }),
    roles: ['USER', 'ADMIN'],
    name: 'Statistics',
  },
  {
    path: '/billing',
    component: AppPageLoader({component: () => import('../components/pages/billing/BillingIndex')}),
    roles: ['USER', 'ADMIN'],
    name: 'Plans & Billing',
  },
  {
    path: '/settings',
    roles: ['USER', 'ADMIN'],
    component: AppPageLoader({
      component: () => import('../components/pages/settings/SettingsIndex'),
    }),
    name: 'Account settings',
  },
  {
    component: AppPageLoader({component: () => import('../components/pages/main/main')}), // default route for unauthenticated visitor
    roles: ['GUEST'],
    key: 'sign-in-page',
  },
  {
    component: AppPageLoader({component: () => import('../components/pages/error-page-not-found')}), // default route for USER and ADMIN
    roles: ['USER', 'ADMIN'],
    key: 'error-page-not-found',
  },
];

export default APP_ROUTES;
