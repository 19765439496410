import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError, setToastSuccess} from './toastMessage';

//feature
export const TOKEN = '[Token]';

//types
export const FETCH_TOKEN = `${TOKEN} FETCH`;
export const CREATE_TOKEN = `${TOKEN} CREATE`;
export const SET_TOKEN = `${TOKEN} SET`;
export const UPDATE_TOKEN = `${TOKEN} UPDATE`;

//creators
export const _fetchToken = () => ({
  type: FETCH_TOKEN,
});
export const _createToken = () => ({
  type: CREATE_TOKEN,
});
export const setToken = token => ({
  type: SET_TOKEN,
  payload: token,
});
export const _updateToken = () => ({
  type: UPDATE_TOKEN,
});

//thunks
export const fetchToken = tokenId => {
  return async dispatch => {
    dispatch(_fetchToken());
    dispatch(setLoader(true, TOKEN));
    await api.token
      .getTokenById(tokenId)
      .then(response => {
        dispatch(setToken(response));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, TOKEN));
  };
};

export const createToken = (clientId, tokenData) => {
  return async dispatch => {
    dispatch(_createToken());
    dispatch(setLoader(true, TOKEN));
    let tokenId = null;

    await api.token
      .createToken(clientId, tokenData)
      .then(response => {
        tokenId = response.id;
        setTimeout(() => dispatch(setToastSuccess({message: 'Token created'})), 100);
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, TOKEN));
    return tokenId;
  };
};

export const updateToken = (clientId, tokenData) => {
  return async dispatch => {
    dispatch(_updateToken());
    dispatch(setLoader(true, TOKEN));
    await api.token
      .updateToken(clientId, tokenData)
      .then(response => {
        dispatch(setToken(response));
        setTimeout(() => dispatch(setToastSuccess({message: 'Token updated'})), 100);
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, TOKEN));
  };
};
