import api from '../../api';

import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const TOKEN_LIST = '[Token list]';

//types
export const FETCH_TOKEN_LIST = `${TOKEN_LIST} FETCH`;
export const SET_TOKEN_LIST = `${TOKEN_LIST} SET`;
export const CLEAR_TOKEN_LIST = `${TOKEN_LIST} CLEAR`;

//creators
export const tokenListFetch = () => ({
  type: FETCH_TOKEN_LIST,
});
export const setTokenList = tokenList => ({
  type: SET_TOKEN_LIST,
  payload: tokenList,
});
export const clearTokenList = () => ({
  type: CLEAR_TOKEN_LIST,
});

//thunks
export const fetchTokenList = () => {
  return async dispatch => {
    dispatch(tokenListFetch());
    dispatch(setLoader(true, TOKEN_LIST));
    await api.clients
      .getAllClients()
      .then(response => {
        const tokenList = response
          .filter(client => client.status !== 'DELETED')
          .filter(client => client.apiTokenList.length !== 0)
          .map(client => {
            return client.apiTokenList.map(token => {
              return {
                allPointCount: client.allPointCount,
                clientId: client.id,
                client: client.name || '',
                username: client.users[0].username,
                ...token,
              };
            });
          })
          .map(client =>
            client.map(client => {
              client.apiGroups.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1));
              return client;
            })
          )
          .reduce((acc, val) => acc.concat(val), []);
        dispatch(setTokenList(tokenList));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, TOKEN_LIST));
      });
    dispatch(setLoader(false, TOKEN_LIST));
  };
};
