import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {selectLandingPageUrl, selectDevelopersPageUrl} from '../../../store/reducers/config';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {MAX_WIDTH_1023} from '../../../config/LAYOUT';

export const LINK_COLUMN_HEIGHT = 112;

const LinkSection = styled.section`
  margin-top: 42px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 118px;
  @media (${MAX_WIDTH_1023}) {
    grid-template-columns: 1fr 1fr;
    width: 50%;
    grid-template-rows: ${LINK_COLUMN_HEIGHT}px ${LINK_COLUMN_HEIGHT / 2}px;
  }
`;

const Column = styled.ul`
  margin-right: 32px;
  width: 104px;
  flex-shrink: 0;
  overflow: visible;
  white-space: nowrap;
  li {
    display: block;
    margin-bottom: 10px;
    height: 18px;
  }
  a {
    font-size: 16px;
    color: white;
  }
`;

const GetCols = ({columnArray}) => (
  <LinkSection>
    {columnArray.map((column, ix) => (
      <Column key={ix}>
        {column.map(link => (
          <li key={link.name}>
            <a rel="noopener noreferrer" target="_blank" href={link.url}>
              {link.name}
            </a>
          </li>
        ))}
      </Column>
    ))}
  </LinkSection>
);

const FooterLinks = ({landingUrl, devUrl}) => {
  const getLandingLink = string => (!landingUrl ? '/404' : landingUrl + string);

  const [width, setWidth] = useState(0);

  useEffect(() => setWidth(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const linkColumns = [
    [
      {name: 'SLA', url: '/404'},
      {name: 'Privacy policy', url: getLandingLink('/privacy_policy')},
      {name: 'EULA', url: getLandingLink('/eula')},
    ],
    [{name: 'Developers', url: devUrl || '/404'}, {name: 'FAQ', url: getLandingLink('/faq')}],
    [
      {name: 'Products', url: getLandingLink('')},
      {name: 'Pricing', url: getLandingLink('/pricing')},
    ],
    [{name: 'Contacts & support', url: getLandingLink('/contacts')}],
  ];

  return <GetCols columnArray={linkColumns} />;
};

FooterLinks.propTypes = {
  landingUrl: PropTypes.string,
  devUrl: PropTypes.string,
};

FooterLinks.defaultProps = {
  landingUrl: '',
  devUrl: '',
};

export default connect(
  state => ({
    landingUrl: selectLandingPageUrl(state),
    devUrl: selectDevelopersPageUrl(state),
  }),
  {}
)(FooterLinks);
