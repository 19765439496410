const messages = {
  sessionTerminated:
    "Your session has been terminated. It's either timed out or you have logged in from another device",
  serverOffline: 'Server is temporarily down',
  startTimeError: 'Start time set after End time',
  endTimeError: 'End time set before Start time',
  userEmailChanged:
    'Change successful, a notification e-mail has been sent to the new e-mail address',
};

export default messages;
