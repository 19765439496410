import React from 'react';
import styled from 'styled-components';
import {Button as SemanticButton, Checkbox as SemanticCheckbox, Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const Button = ({ghost, disabled, children, onClick, ...rest}) => (
  <StyledButton onClick={onClick} disabled={disabled} ghost={ghost ? 'true' : null} {...rest}>
    {children}
  </StyledButton>
);

Button.propTypes = {
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  ghost: false,
  disabled: false,
};

const StyledButton = styled(SemanticButton)`
  &.ui.button,
  &.ui.button:focus {
    box-sizing: initial;
    min-width: 76px;
    padding: 8px 24px 12px;
    background: ${({ghost, disabled}) =>
      ghost ? 'none' : disabled ? 'var(--button-disabled)' : 'var(--primary-light)'};
    color: ${({ghost, disabled}) =>
      ghost ? 'var(--text-black)' : disabled ? 'var(--text-black-60perc)' : 'var(--text-white)'};
    border-radius: 24px;
    border: ${({ghost, disabled}) =>
      (ghost && !disabled && '1px solid var(--primary-light)') || 'none'};
    font-weight: normal;
    pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
    transition: 100ms;
  }
  &.ui.button:hover {
    background: ${({ghost}) => (ghost ? 'var(--primary-light)' : 'var(--primary-hover)')};
    border-color: ${({ghost}) => (ghost ? 'var(--primary-light)' : 'var(--primary-hover)')};
    color: var(--text-white);
  }
  &.ui.button:active {
    color: var(--text-white);
    background: var(--primary-click);
    border-color: var(--primary-click);
  }
`;

export const CheckBox = styled(SemanticCheckbox)`
  &.ui.checkbox {
    display: block;
  }
  &.ui.checkbox input:focus ~ label:before,
  &.ui.checkbox label:before {
    border-radius: 50%;
    border-color: var(--primary-light);
    background: transparent;
  }
  &.ui.checkbox.checked input:focus ~ label:before,
  &.ui.checkbox.checked label:before {
    background: var(--primary-light);
    border-color: var(--primary-light);
  }
  &.ui.checkbox.checked input:focus ~ label:after,
  &.ui.checkbox.checked label:after {
    font-size: 11px;
    border-color: var(--primary-light);
    color: var(--text-white);
  }
  &.ui.checkbox input:focus ~ label:hover::before,
  &.ui.checkbox label:hover::before {
    ${({disabled}) =>
      !disabled ? 'border-color: var(--primary-hover);' : 'border-color: var(--primary-light);'};
    ${({disabled, checked}) => (disabled && !checked ? 'background: transparent;' : '')};
  }
`;

export const StyledInput = styled(Form.Input)`
  &.field .ui.input input {
    width: ${({inputwidth, errorWidth}) =>
      !errorWidth ? (inputwidth || '340') + 'px' : inputwidth} !important;
    height: 50px;
    padding: ${({padding}) => padding || '8px 13px 13px'};
    color: var(--text-black-60perc);
    border-radius: 3px;
    transition: border 100ms;
    border: 1px solid;
    border-color: ${({border}) => (border ? 'rgba(155, 189, 202, 0.5)' : 'transparent')};
    background: ${({background}) => background || 'auto'};
    margin-right: ${({error, errorWidth}) => (errorWidth || error ? null : '36px')};

    &:hover {
      border-color: var(--secondary-blue);
    }

    &:focus {
      border-color: var(--text-black);
    }
  }

  &.field .ui.disabled.input {
    opacity: 0.9;
  }

  &.field.error .ui.input input {
    background: var(--secondary-yellow-20perc) !important;

    &:hover {
      border-color: var(--secondary-red);
    }

    &:focus {
      border-color: var(--text-black) !important;
    }
  }

  &.field {
    margin-bottom: 12px !important;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 8px;
    }
  }

  &.field.error {
    margin-right: 0 !important;
  }
`;

const Field = styled.div`
  margin-left: 36px;
  display: flex;
`;

const ErrorIcon = styled.div`
  margin: 0 0 8px 16px;
  height: 20px;
  width: 20px;
  background: url(/images/error-circle.svg);
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  align-self: center;

  &:before {
    content: '';
    background: var(--secondary-yellow);
    display: block;
    height: 1px;
    width: 10px;
    position: absolute;
    top: 9px;
    left: -10px;
    opacity: 0;
    transition: 150ms;
    pointer-events: none;
  }
  &:hover::before,
  &:hover div {
    opacity: 1;
    pointer-events: auto;
  }
`;

const Container = styled.div`
  padding-right: 10px;
  width: ${({inputwidth}) => inputwidth + 10 || 350}px;
  min-height: 50px;
  position: absolute;
  top: -100%;
  left: -${({inputwidth}) => inputwidth + 10 || 350}px;
  z-index: 2;
  pointer-events: none;
`;

const MessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({inputwidth}) => inputwidth || 340}px;
  min-height: 50px;
  padding: 8px 8px 12px;
  color: var(--text-error);
  background: var(--text-white);
  border: 1px solid var(--secondary-yellow);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(50, 50, 50, 0.12);
  opacity: 0;
  transition: 150ms;
  cursor: initial;

  ul {
    list-style: disc outside;
  }

  ul li {
    margin: 4px 12px 8px 24px;
  }

  &:hover {
    opacity: 1;
  }
`;

const StyledTextarea = styled(Form.TextArea)`
  &.field textarea {
    width: ${({inputwidth}) => inputwidth || '340'}px !important;
    min-height: 50px;
    padding: ${({padding}) => padding || '13px'};
    color: ${({color}) => color || 'var(--text-black-60perc)'};
    border-radius: 3px;
    transition: border 100ms;
    border: 1px solid;
    border-color: ${({border}) => (border ? 'rgba(155, 189, 202, 0.5)' : 'transparent')};
    background: ${({background}) => background || 'auto'};
    margin-right: ${({error}) => (error ? null : '36px')};
    resize: none;
    max-height: 250px;
    overflow: hidden;

    &:hover {
      border-color: ${({border}) => (border ? 'var(--secondary-blue)' : 'transparent')};
    }

    &:focus {
      border-color: var(--text-black);
    }

    &.field textarea:disabled:hover {
      border-color: transparent;
    }
  }

  &.field.error textarea {
    background: var(--secondary-yellow-20perc) !important;

    &:hover {
      border-color: var(--secondary-red);
    }

    &:focus {
      border-color: var(--text-black) !important;
    }
  }

  &.field {
    margin-bottom: 12px !important;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 8px;
    }
  }

  &.field.error {
    margin-right: 0 !important;
  }

  &.field textarea::placeholder {
    color: var(--text-black-50perc);
  }

  &.field textarea::-webkit-input-placeholder {
    color: var(--text-black-50perc);
  }
`;

//if errorWidth (number) is provided the inputWidth should be entered as string '300px', otherwise as number 300
export const Input = props => {
  const {errorMessage, testId, inputwidth, errorWidth, ...rest} = props;
  return (
    <Field className="input-field">
      <StyledInput
        {...rest}
        error={!!errorMessage}
        errorWidth={errorWidth}
        inputwidth={inputwidth}
      />
      {!!errorMessage && (
        <ErrorMessage errorMessage={errorMessage} id={testId} width={errorWidth || inputwidth} />
      )}
    </Field>
  );
};

export const Textarea = props => {
  const {errorMessage, testId, inputwidth, ...rest} = props;
  return (
    <Field className="input-field">
      <StyledTextarea {...rest} error={!!errorMessage} inputwidth={inputwidth} />
      {!!errorMessage && (
        <ErrorMessage errorMessage={errorMessage} id={testId} width={inputwidth} />
      )}
    </Field>
  );
};

const ErrorMessage = ({errorMessage, id, width}) => {
  return (
    <>
      <ErrorIcon className="input-error" data-testid={`${id}-error`}>
        <Container inputwidth={width}>
          <MessageBox inputwidth={width} dangerouslySetInnerHTML={{__html: errorMessage}} />
        </Container>
      </ErrorIcon>
    </>
  );
};

Input.defaultProps = {
  errorMessage: 'Error',
};
