/**
 * @author:  jens@reach-u.com
 * @since: 2018-06-11
 */

import api from '../api';
import store from '../../store';
import {getAuthorizationToken} from '../authorizationToken';
import BGM_PORTAL_API_BASEURL from '../../config/BGM_PORTAL_API_BASEURL';
import {selectAuthHeaderNamespace} from '../../store/reducers/config';

/**
 * invoice-controller : Invoice Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/invoice45controller
 */

const hasAdminRole = () => {
  return !!store.getState().appUser.authorities.find(authority => authority.authority === 'ADMIN');
};

const invoice = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/findAllUsingGET
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/findAllUsingGET_1
   * Returns all invoices for ADMIN
   * Returns all client invoices for current USER
   */
  findAll: () =>
    api.get(hasAdminRole() ? '/api/1/invoice/admin' : '/api/1/invoice').then(response =>
      response.map(invoice => ({
        ...invoice,
        created: Date.parse(invoice.created),
      }))
    ),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/findFileUsingGET
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/findFileUsingGET_1
   * Returns any pdf invoice file for ADMIN
   * Returns pdf invoice file for current USER
   */
  findFile: filename => {
    return new Promise((resolve, reject) => {
      const url = (hasAdminRole()
        ? '/api/1/invoice/admin/pdf/{filename}'
        : '/api/1/invoice/pdf/{filename}'
      ).replace(/{filename}/, filename);
      const xhr = new XMLHttpRequest();
      xhr.open('GET', BGM_PORTAL_API_BASEURL + url, true);
      xhr.responseType = 'arraybuffer';
      xhr.onload = function() {
        switch (this.status) {
          case 200:
            resolve(this.response);
            break;
          default:
            reject(new Error(this.statusText));
            break;
        }
      };
      xhr.onerror = function() {
        reject(new Error('XMLHttpRequest error'));
      };
      xhr.setRequestHeader('Content-type', 'application/pdf');
      xhr.setRequestHeader(selectAuthHeaderNamespace(store.getState()), getAuthorizationToken());
      xhr.send();
    });
  },

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/billingStatusUsingGET
   * Returns billing status for current USER
   */
  billingStatus: () => api.get('/api/1/invoice/status'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/updateInvoiceStatusUsingPUT
   * Updates the status (PAID/UNPAID) of invoice of with given id , for ADMIN user only
   */

  updateInvoiceStatus: (id, status) =>
    api.put(
      '/api/1/invoice/update_status/{invoiceId}'.replace(/{invoiceId}/, id) + '?status=' + status
    ),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/invoice45controller/findAllByClientIdUsingGET
   * Finds all invoices of a client by clientId, ADMIN only
   */

  findAllByClientId: clientId =>
    api.get('/api/1/invoice/{clientId}'.replace(/{clientId}/, clientId)),
};

export default invoice;
