import {RESET_ALL} from '../constants';
import {SET_TOKEN} from '../actions/token';

const initialState = {
  /*
 "id": 1,
 "name": "tokenName",
 "token": "eyJhbGciOiJIUzI1NiJ9...etc",
 "apiGroups": [],
 "createdAt": 1526907810278,
 "lastModified": 1526907896958,
 "status": "ENABLED"
 */
};

export default function token(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TOKEN:
      return action.payload;
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
