/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-15
 */

import {RESET_ALL} from '../constants';
import {SET_PRICING_PLAN_LIST, CLEAR_PRICING_PLAN_LIST} from '../actions/pricingPlanList';

const initialState = [
  /*
  {
    "id": 1,
    "strategy": "FIXED_MONTH",
    "name": "Trial",
    "description": null,
    "price": 0,
    "pointLimit": 100,
    "pointPrice": null
  },
  ...
  */
];

export default function pricingPlanList(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PRICING_PLAN_LIST: {
      return action.payload;
    }
    case CLEAR_PRICING_PLAN_LIST:
    case RESET_ALL: {
      return initialState;
    }
    default:
      return state;
  }
}
