/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-24
 */
import {RESET_ALL} from '../constants';
import {
  SET_USER_CLIENT,
  CLEAR_USER_CLIENT,
  CANCEL_USER_CLIENT_ACCOUNT,
  REINSTATE_USER_CLIENT_ACCOUNT,
  SUSPEND_USER_CLIENT,
  UNSUSPEND_USER_CLIENT,
  CHANGE_USER_CLIENT_OWNER,
} from '../actions/appUserClient';

export const initialState = {
  id: null,
  name: '', //called company name in UI
  email: '', // <-- client.users[0].username
  address: '', //called billing address in UI
  registerCode: '',
  contactPersonName: '',
  contactPersonPhone: '',
  billingEmail: '',
  vatNumber: '',
  status: null,
  users: [],
  apiTokenList: [],
  createdAt: null,
  lastModified: null,
  toBeDeleted: false,
  suspensionReason: '',
  accountOwner: '',
};

export function appUserClient(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_CLIENT: {
      const {
        id,
        name,
        address,
        registerCode,
        contactPersonName,
        contactPersonPhone,
        billingEmail,
        status,
        users,
        apiTokenList,
        createdAt,
        lastModified,
        toBeDeleted,
        vatNumber,
        accountOwner,
      } = action.payload;
      const email = users[0].username;
      return {
        ...state,
        id,
        name,
        email,
        address,
        registerCode,
        contactPersonName,
        contactPersonPhone,
        billingEmail,
        status,
        users,
        apiTokenList,
        createdAt,
        lastModified,
        toBeDeleted,
        vatNumber,
        accountOwner,
      };
    }
    case CANCEL_USER_CLIENT_ACCOUNT: {
      return {...state, toBeDeleted: true};
    }
    case REINSTATE_USER_CLIENT_ACCOUNT: {
      return {...state, toBeDeleted: false};
    }
    case SUSPEND_USER_CLIENT:
      return {...state, status: 'SUSPENDED', suspensionReason: action.payload};
    case UNSUSPEND_USER_CLIENT:
      return {...state, status: 'ACTIVE', suspensionReason: ''};
    case CHANGE_USER_CLIENT_OWNER:
      return {...state, accountOwner: action.payload};
    case CLEAR_USER_CLIENT:
    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}

export const selectUserBillingInfo = state => {
  const {
    name,
    address,
    billingEmail,
    registerCode,
    vatNumber,
    contactPersonName,
    contactPersonPhone,
  } = state.appUserClient;
  return {
    name,
    address,
    billingEmail,
    registerCode,
    vatNumber,
    contactPersonName,
    contactPersonPhone,
  };
};

export const selectAccountDeletionStatus = state => state.appUserClient.toBeDeleted;

export const selectAccountID = state => state.appUserClient.id;
