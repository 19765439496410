import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {MAX_WIDTH_1023} from '../../../config/LAYOUT';
import {LINK_COLUMN_HEIGHT} from './FooterLinks';

const Content = styled.section`
  margin-top: 28px;

  img {
    margin-bottom: 36px;
    @media (${MAX_WIDTH_1023}) {
      margin-bottom: ${LINK_COLUMN_HEIGHT + 36 - 28}px;
    }

    width: 160px;
  }
  p {
    font-size: 14px;
    color: var(--text-white);
    line-height: 19px;
  }
`;

const LogoContent = ({id}) => (
  <Content>
    {id ? (
      <Link to="/billing/status">
        <img width="160" src="/images/logo_dark_bg.svg" alt="GeoDataHub logo" />
      </Link>
    ) : (
      <img width="160" src="/images/logo_dark_bg.svg" alt="GeoDataHub logo" />
    )}
    <p>GeoDataHub © 2019</p>
  </Content>
);

LogoContent.propTypes = {
  id: PropTypes.number,
};

LogoContent.defaultProps = {
  id: null,
};

export default connect(state => ({
  id: state.appUser.id,
}))(LogoContent);
