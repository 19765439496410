/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import messageArea from './reducers/messageArea';
import appLoader from './reducers/appLoader';
import config from './reducers/config';
import apiServiceDetails from './reducers/apiServiceDetails';
import apiServiceList from './reducers/apiServiceList';
import apiServiceGroupList from './reducers/apiServiceGroupList';
import appUser from './reducers/appUser';
import {appUserClient} from './reducers/appUserClient';
import appUserClientPricingPlan from './reducers/appUserPricingPlan';
import appUserClientPricingPlanPending from './reducers/appUserPricingPlanPending';
import {appUserTrialPlan} from './reducers/appUserTrialPlan';
import clientDetails from './reducers/clientDetails';
import clientList from './reducers/clientList';
import clientPricingPlan from './reducers/clientPricingPlan';
import clientPricingPlanPending from './reducers/clientPricingPlanPending';
import {clientTrialPlan} from './reducers/clientTrialPlan';
import formLoader from './reducers/formLoader';
import invoiceList from './reducers/invoiceList';
import pricingPlanList from './reducers/pricingPlanList';
import statistics from './reducers/statistics';
import statisticsFilters from './reducers/statisticsFilters';
import toastMessage from './reducers/toastMessage';
import token from './reducers/token';
import tokenList from './reducers/tokenList';
import regionsAndVendors from './reducers/regionsAndVendors';
import tokenVendorDetails from './reducers/tokenVendorDetails';

const rootReducer = combineReducers({
  messageArea,
  appLoader,
  config,
  apiServiceDetails,
  apiServiceGroupList,
  apiServiceList,
  appUser,
  appUserClient: appUserClient || (() => ({})),
  appUserClientPricingPlan,
  appUserClientPricingPlanPending,
  appUserTrialPlan: appUserTrialPlan || (() => ({})), //This solves Jest error 'No reducer provided for key ...' --> https://stackoverflow.com/a/43431439
  clientDetails,
  clientList,
  clientPricingPlan,
  clientPricingPlanPending,
  clientTrialPlan: clientTrialPlan || (() => ({})),
  formLoader,
  invoiceList,
  pricingPlanList,
  statistics,
  statisticsFilters,
  toastMessage,
  token,
  tokenList,
  regionsAndVendors,
  tokenVendorDetails,
});

/**
 * https://github.com/zalmoxisus/redux-devtools-extension
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
