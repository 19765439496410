/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import {setLoader} from './appLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import api from '../../api';
import {getEndMsOfCurrentMonth, getStartMsOfCurrentMonth} from '../../utils/date';
import {fetchClientPendingPricingPlan} from './clientPricingPlanPending';

//feature
export const CLIENT_PRICING_PLAN = '[Client pricing plan]';

//types
export const FETCH_CLIENT_PRICING_PLAN = `${CLIENT_PRICING_PLAN} FETCH`;
export const SET_CLIENT_PRICING_PLAN = `${CLIENT_PRICING_PLAN} SET`;

//creators
export const clientPricingPlanFetch = () => ({
  type: FETCH_CLIENT_PRICING_PLAN,
});
export const setClientPricingPlan = plan => ({
  type: SET_CLIENT_PRICING_PLAN,
  payload: plan,
});

//thunks
export const fetchClientPricingPlan = clientId => {
  return async dispatch => {
    dispatch(clientPricingPlanFetch());
    dispatch(setLoader(true, CLIENT_PRICING_PLAN));
    await api.pricingPlan
      .findClientActive(clientId)
      .then(response => {
        const periodStart = getStartMsOfCurrentMonth();
        const periodEnd = getEndMsOfCurrentMonth();
        dispatch(
          setClientPricingPlan({
            ...response,
            periodStart,
            periodEnd,
            activationDate: Date.parse(response.activationDate),
          })
        );
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT_PRICING_PLAN));
      });
    dispatch(setLoader(false, CLIENT_PRICING_PLAN));
  };
};

export const updateClientPricingPlan = (clientId, pricingPlan) => {
  return async dispatch => {
    dispatch(setLoader(true, CLIENT_PRICING_PLAN));
    await api.pricingPlan
      .changeClientPlan(clientId, pricingPlan)
      .then(() => {
        dispatch(fetchClientPricingPlan(clientId));
        dispatch(fetchClientPendingPricingPlan(clientId));
        dispatch(setToastSuccess({message: 'Pricing plan changed'}, CLIENT_PRICING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT_PRICING_PLAN));
      });
    dispatch(setLoader(false, CLIENT_PRICING_PLAN));
  };
};

export const clientPricingPlanPayAsYouGoOptIn = clientId => {
  return async dispatch => {
    dispatch(setLoader(true, CLIENT_PRICING_PLAN));
    await api.clients
      .optClientIn(clientId)
      .then(() => {
        dispatch(fetchClientPricingPlan(clientId));
        dispatch(setToastSuccess({message: 'Pricing plan updated'}, CLIENT_PRICING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT_PRICING_PLAN));
      });
    dispatch(setLoader(false, CLIENT_PRICING_PLAN));
  };
};

export const clientPricingPlanPayAsYouGoOptOut = clientId => {
  return async dispatch => {
    dispatch(setLoader(true, CLIENT_PRICING_PLAN));
    await api.clients
      .optClientOut(clientId)
      .then(() => {
        dispatch(fetchClientPricingPlan(clientId));
        dispatch(setToastSuccess({message: 'Pricing plan updated'}, CLIENT_PRICING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT_PRICING_PLAN));
      });
    dispatch(setLoader(false, CLIENT_PRICING_PLAN));
  };
};
