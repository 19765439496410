/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-02
 */
import {RESET_ALL} from '../constants';
import {UPDATE_MESSAGE_AREA, SET_MESSAGE_AREA, CLEAR_MESSAGE_AREA} from '../actions/messageArea';

let initialState = {
  areaVisible: false,
  content: {},
  priority: 0,
  queue: [],
};

export default function messageArea(state = initialState, action = {}) {
  switch (true) {
    case action.type.includes(UPDATE_MESSAGE_AREA):
      return {
        ...state,
        ...action.payload,
      };
    case action.type.includes(SET_MESSAGE_AREA):
      return {...state, areaVisible: action.payload};
    case action.type.includes(CLEAR_MESSAGE_AREA):
    case action.type === RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
