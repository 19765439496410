import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const REGIONS_AND_VENDORS = '[Regions and vendors]';

//types
//types
export const FETCH_REGIONS_AND_VENDORS = `${REGIONS_AND_VENDORS} FETCH`;
export const SET_REGIONS_AND_VENDORS = `${REGIONS_AND_VENDORS} SET`;
export const CLEAR_REGIONS_AND_VENDORS = `${REGIONS_AND_VENDORS} CLEAR`;

//creators
export const fetchRegionsAndVendors = () => ({
  type: FETCH_REGIONS_AND_VENDORS,
});
export const setRegionsAndVendors = data => ({
  type: SET_REGIONS_AND_VENDORS,
  payload: data,
});
export const clearRegionsAndVendors = () => ({
  type: CLEAR_REGIONS_AND_VENDORS,
});

//thunks
export const fetchRegionAndVendorData = () => {
  return async dispatch => {
    dispatch(fetchRegionsAndVendors());
    dispatch(setLoader(true, REGIONS_AND_VENDORS));
    await api.vendors
      .getRegionsAndVendors()
      .then(response => dispatch(setRegionsAndVendors(response)))
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, REGIONS_AND_VENDORS));
  };
};
