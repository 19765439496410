/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import moment from 'moment';

import {SET_FILTERS, CLEAR_FILTERS} from '../actions/statisticsFilters';
import {RESET_ALL} from '../constants';
import {maxRequestSize, maxTableRows} from '../../config/CONSTANTS';

const initialState = {
  clientIds: [],
  endTime: moment(),
  responseStatuses: [],
  granularity: 'DAY',
  services: [],
  startTime: moment()
    .subtract(1, 'w')
    .subtract(1, 's'),
  tokenIds: [],
  page: 0,
  size: maxRequestSize,
  httpStatusGroups: [],
  tableSize: maxTableRows[0],
};

export default function statisticsFilters(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FILTERS:
      return {...state, ...action.payload};
    case CLEAR_FILTERS:
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
