/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-15
 */

import api from '../../api';
import moment from 'moment';

import {getEndMsOfCurrentMonth, getStartMsOfCurrentMonth} from '../../utils/date';
import {addMessageAreaContent} from './messageArea';
import {pointLimitWillBeExceeded} from '../../config/MESSAGE_AREA_MESSAGES';
import {fetchAppUserClientPricingPlanPending} from './appUserPricingPlanPending';
import {setLoader} from './appLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import {fetchUserTrialPlan} from './appUserTrialPlan';

//feature
export const USER_PRICING_PLAN = '[User pricing plan]';

//types
export const FETCH_USER_PRICING_PLAN = `${USER_PRICING_PLAN} FETCH`;
export const SET_USER_PRICING_PLAN = `${USER_PRICING_PLAN} SET`;
export const UPDATE_USER_PRICING_PLAN = `${USER_PRICING_PLAN} UPDATE`;
export const REFRESH_USER_PRICING_PLAN = `${USER_PRICING_PLAN} REFRESH`;
export const PRICING_PLAN_OPT_IN = `${USER_PRICING_PLAN} OPT IN`;
export const PRICING_PLAN_OPT_OUT = `${USER_PRICING_PLAN} OPT OUT`;
export const CLEAR_USER_PRICING_PLAN = `${USER_PRICING_PLAN} CLEAR`;

//creators
export const fetchUserPricingPlan = () => ({
  type: FETCH_USER_PRICING_PLAN,
});
export const setUserPricingPlan = pricingPlan => ({
  type: SET_USER_PRICING_PLAN,
  payload: pricingPlan,
});
export const updateUserPricingPlan = () => ({
  type: UPDATE_USER_PRICING_PLAN,
});
export const refreshUserPricingPlan = () => ({
  type: REFRESH_USER_PRICING_PLAN,
});
export const UserOptIn = () => ({
  type: PRICING_PLAN_OPT_IN,
});
export const UserOptOut = () => ({
  type: PRICING_PLAN_OPT_OUT,
});
export const clearUserPricingPlan = () => ({
  type: CLEAR_USER_PRICING_PLAN,
});

//thunks
export const fetchAppUserClientPricingPlan = () => {
  return async dispatch => {
    dispatch(fetchUserPricingPlan());
    dispatch(setLoader(true, USER_PRICING_PLAN));
    await api.pricingPlan
      .findActive()
      .then(payload => {
        const periodStart = getStartMsOfCurrentMonth();
        const periodEnd = getEndMsOfCurrentMonth();
        dispatch(
          setUserPricingPlan({
            ...payload,
            periodStart,
            periodEnd,
            activationDate: Date.parse(payload.activationDate),
          })
        );
        if (!payload.price && !payload.strategy) {
          dispatch(fetchUserTrialPlan());
        }
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_PRICING_PLAN));
      });
    dispatch(setLoader(false, USER_PRICING_PLAN));
  };
};

export const refreshAppUserClientPricingPlan = () => {
  return async dispatch => {
    dispatch(refreshUserPricingPlan());
    await api.pricingPlan.findActive().then(payload => {
      const periodStart = getStartMsOfCurrentMonth();
      const periodEnd = getEndMsOfCurrentMonth();
      dispatch(
        setUserPricingPlan({
          ...payload,
          periodStart,
          periodEnd,
          activationDate: Date.parse(payload.activationDate),
        })
      );
    });
  };
};

export const updateAppUserClientPricingPlan = pricingPlan => {
  return async dispatch => {
    dispatch(updateUserPricingPlan());
    dispatch(setLoader(true, USER_PRICING_PLAN));
    await api.pricingPlan
      .change(pricingPlan)
      .then(() => {
        dispatch(fetchAppUserClientPricingPlan());
        dispatch(fetchAppUserClientPricingPlanPending());
        dispatch(setToastSuccess({message: 'Pricing plan changed'}, USER_PRICING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_PRICING_PLAN));
      });
    dispatch(setLoader(false, USER_PRICING_PLAN));
  };
};

export const appUserClientPricingPlanPayAsYouGoOptIn = () => {
  return async dispatch => {
    dispatch(UserOptIn());
    dispatch(setLoader(true, USER_PRICING_PLAN));
    await api.clients
      .optIn()
      .then(() => {
        dispatch(fetchAppUserClientPricingPlan());
        dispatch(setToastSuccess({message: 'Pricing plan updated'}, USER_PRICING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_PRICING_PLAN));
      });
    dispatch(setLoader(false, USER_PRICING_PLAN));
  };
};

export const appUserClientPricingPlanPayAsYouGoOptOut = () => {
  return async dispatch => {
    dispatch(UserOptOut());
    dispatch(setLoader(true, USER_PRICING_PLAN));
    await api.clients
      .optOut()
      .then(() => {
        dispatch(fetchAppUserClientPricingPlan());
        dispatch(setToastSuccess({message: 'Pricing plan updated'}, USER_PRICING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_PRICING_PLAN));
      });
    dispatch(setLoader(false, USER_PRICING_PLAN));
  };
};

export const getAppUserClientUsageRate = () => (dispatch, getState) => {
  const {id} = getState().appUserClientPricingPlan;
  if (id === null) {
    dispatch(fetchAppUserClientPricingPlan()).then(() => {
      dispatch(calculateRate());
    });
  } else {
    dispatch(calculateRate());
  }
};

export const calculateRate = () => (dispatch, getState) => {
  const {
    periodEnd,
    periodStart,
    pointCount,
    pointLimit,
    strategy,
  } = getState().appUserClientPricingPlan;
  const rate = Math.ceil(
    pointCount / moment.duration(moment().diff(moment(periodStart), 'ms')).as('days')
  );
  dispatch(setUserPricingPlan({...getState().appUserClientPricingPlan, pointsPerDay: rate}));
  const estimatedCount = rate * moment.duration(moment(periodEnd).diff(moment(), 'ms')).as('days');
  if (estimatedCount > pointLimit - pointCount && strategy && strategy.includes('FIXED')) {
    dispatch(
      addMessageAreaContent(pointLimitWillBeExceeded.content, pointLimitWillBeExceeded.priority)
    );
  }
};
