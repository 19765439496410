/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-30
 */

import {RESET_ALL} from '../constants';
import {SET_SERVICE_DETAILS, CLEAR_SERVICE_DETAILS} from '../actions/apiServiceDetails';

const initialState = {
  id: null,
  name: null,
  groups: [],
  methods: [],
  uris: null,
  upstreamUrl: null,
  status: null,
  statusTestUrl: null,
  clients: [],
};

export default function apiServiceDetails(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SERVICE_DETAILS: {
      const {
        id,
        name,
        groups,
        methods,
        uris,
        upstreamUrl,
        status,
        statusTestUrl,
        clients,
        order,
      } = action.payload;
      return {
        id,
        name,
        groups,
        methods: methods || [],
        uris,
        upstreamUrl,
        status,
        statusTestUrl,
        clients,
        order,
      };
    }
    case CLEAR_SERVICE_DETAILS:
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
