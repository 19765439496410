/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-13
 */

import api from './api';

import auth from './controllers/auth';
import clients from './controllers/clients';
import invoice from './controllers/invoice';
import pricingPlan from './controllers/pricing-plan';
import signUp from './controllers/sign-up';
import token from './controllers/token';
import user from './controllers/user';
import services from './controllers/services';
import statistics from './controllers/statistics';
import vendors from './controllers/vendors';
import config from './controllers/config';

api.auth = auth;
api.clients = clients;
api.invoice = invoice;
api.pricingPlan = pricingPlan;
api.services = services;
api.signUp = signUp;
api.token = token;
api.user = user;
api.statistics = statistics;
api.vendors = vendors;
api.config = config;

export default api;
