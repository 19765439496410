/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import AppLoader from './components/app/AppLoader';
import AppHeader from './components/app/header/AppHeader';
import AppContent from './components/app/AppContent';
import SessionExpiresMessage from './components/elements/SessionExpiresMessage';
import ToastContainer from './components/elements/ToastMessage';

import {appUserAuth} from './store/actions/appUser';
import {getConfig} from './store/actions/config';

import 'semantic-ui-css/semantic.min.css';
import './style/common.css';

class App extends React.Component {
  state = {
    isLoaded: false,
  };

  render() {
    const {isLoaded} = this.state;
    const {messageAreaVisible, id} = this.props;

    return (
      <BrowserRouter>
        {isLoaded && (
          <React.Fragment>
            <AppLoader />
            <AppHeader />
            <AppContent messageAreaVisible={messageAreaVisible} loggedIn={!!id} />
            <SessionExpiresMessage />
            <ToastContainer />
          </React.Fragment>
        )}
      </BrowserRouter>
    );
  }

  componentDidMount() {
    this.props.getConfig().then(() => {
      this.props.appUserAuth().then(() => {
        this.setState({isLoaded: true});
      });
    });
  }
}

App.propTypes = {
  appUserAuth: PropTypes.func.isRequired,
  getConfig: PropTypes.func.isRequired,
  messageAreaVisible: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    messageAreaVisible: state.messageArea.areaVisible,
    id: state.appUser.id,
  }),
  {appUserAuth, getConfig}
)(App);
