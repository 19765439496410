/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-22
 */

import api from '../../api';

import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const CLIENT_LIST = '[Client list]';

//types
export const FETCH_CLIENT_LIST = `${CLIENT_LIST} FETCH`;
export const SET_CLIENT_LIST = `${CLIENT_LIST} SET`;
export const CLEAR_CLIENT_LIST = `${CLIENT_LIST} CLEAR`;

//creators
export const clientListFetch = () => ({
  type: FETCH_CLIENT_LIST,
});
export const setClientList = clientList => ({
  type: SET_CLIENT_LIST,
  payload: clientList,
});
export const clearClientList = () => ({
  type: CLEAR_CLIENT_LIST,
});

//thunks
export const fetchClientList = () => {
  return async dispatch => {
    dispatch(clientListFetch());
    dispatch(setLoader(true, CLIENT_LIST));
    await api.clients
      .getAllClients()
      .then(response => {
        dispatch(
          setClientList(
            response.map(client => {
              return {...client, email: client.users[0].username};
            })
          )
        );
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, CLIENT_LIST));
  };
};
