/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-24
 */

import {SET_FORM_LOADER} from '../actions/formLoader';

const initialState = false;

export default function formLoader(state = initialState, action = {}) {
  switch (true) {
    case action.type.includes(SET_FORM_LOADER):
      return action.payload;
    default:
      return state;
  }
}
