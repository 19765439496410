/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-11
 */

import {format} from 'd3-format';
import moment from 'moment';
import errorMessages from '../config/ERROR_MESSAGES';

export const toUpperCaseFirst = str => {
  str = (str || '').toString();
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const includesCaseInsensitive = (stringA, stringB) =>
  stringA.toUpperCase().includes(stringB.toUpperCase());

export const sanitizeWhiteSpace = string => string.replace(/\s+/g, ' ').trim();

export const sortArrayBy = (data, sortBy, sortOrder) => {
  data.sort((a, b) => {
    a = a[sortBy];
    b = b[sortBy];
    if (a === null || a === undefined) {
      a = '';
    } else if (typeof a === 'object') {
      a = JSON.stringify(a);
    } else if (typeof a === 'string') {
      a = a.toLowerCase();
    }
    if (b === null || b === undefined) {
      b = '';
    } else if (typeof b === 'object') {
      b = JSON.stringify(b);
    } else if (typeof b === 'string') {
      b = b.toLowerCase();
    }
    return a === b ? 0 : a > b ? 1 : -1;
  });

  if (sortOrder === 'descending') {
    data.reverse();
  }
  return data;
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const fillRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((item, index) => start + index);
};

export const isProperNumber = number => {
  number = parseInt(number, 10);
  return number === parseInt(number, 10);
};

export const SIFormatter = value => format('.0s')(value);

export const addThousandSeparator = (number, separator = ',') =>
  isProperNumber(number)
    ? number.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1' + separator)
    : number;

export const flatten = arr => arr.reduce((flat, next) => flat.concat(next), []);

export const BillingUtils = {
  /* used points / current days = avg pts per day (PpD)
   * PpD * days left in period = estimated points
   * current billable amount + (estimated spending * point price) = estimated total bill
   * */
  calculateEstimate: ({currentPoints, periodStart, periodEnd, pointPrice, price = 0}) => {
    const currentPeriod = Math.abs(moment.duration(moment(periodStart).diff(moment())).as('days'));
    const daysUntilEnd = Math.abs(moment.duration(moment().diff(moment(periodEnd))).as('days'));
    const pointsPerDay = BillingUtils.pointsPerDay({
      pointsUsed: currentPoints,
      days: currentPeriod,
    });
    const estimatedBill =
      BillingUtils.getEstimatedPoints({pointsPerDay, period: daysUntilEnd}) * pointPrice;

    return estimatedBill + price;
  },
  pointsPerDay: ({pointsUsed, days = 1}) => {
    return +(pointsUsed / days).toFixed(0);
  },
  getEstimatedPoints: ({pointsPerDay, period}) => {
    return pointsPerDay * period;
  },
};

export const nameFieldFormatErrorGenerator = name => {
  const {
    inputErrors: {onlyWhitespaceInName, noAlphaNumericInName},
  } = errorMessages;
  if (!/\S/.test(name)) return onlyWhitespaceInName;
  if (!/[a-z0-9]/.test(name)) return noAlphaNumericInName;

  return null;
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{[keysMap[key] || key]: obj[key]},
    }),
    {}
  );

//NB! do not input array items that already have a property called "position"
export const stableSort = (array, sortFunc, reverse = false) => {
  const indexed = [...array].map((item, ix) => ({...item, position: ix}));
  const out = [...indexed]
    .sort((a, b) => {
      if (sortFunc(a, b) === 0) {
        return reverse ? b.position - a.position : a.position - b.position;
      }
      return sortFunc(a, b);
    })
    .map(item => {
      delete item['position'];
      return item;
    });
  return reverse ? out.reverse() : out;
};

// prettier-ignore
export const toMillionsAndThousandsLabel = number =>
  Math.abs(Number(number)) >= 1.0e6
    ? Math.abs(Number(number)) / 1.0e6 + ' M'
    : Math.abs(Number(number)) >= 1.0e3
      ? Math.abs(Number(number)) / 1.0e3 + ' K'
      : number;

const dec2hex = decimal => ('0' + decimal.toString(16)).substr(-2);

export const generateID = () => {
  const array = new Uint8Array(10);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
};

export const createMillionsLabel = (number, thousandSeparator) =>
  isProperNumber(number) ? toMillions(number, thousandSeparator) : number;

export const replaceUndefinedValues = object => {
  for (let key in object) {
    if (object.hasOwnProperty(key) && object[key] === undefined) {
      object[key] = '';
    }
    if (object.hasOwnProperty(key) && typeof object[key] === 'object') {
      replaceUndefinedValues(object[key]);
    }
  }
  return object;
};

const toMillions = (number, thousandSeparator) =>
  Math.abs(Number(number)) >= 1.0e6
    ? Math.abs(Number(number)) / 1.0e6 + ' M'
    : addThousandSeparator(number, thousandSeparator);

export const replaceNullValuesWithEmptyStrings = object => {
  for (let key in object) {
    if (object.hasOwnProperty(key) && object[key] === null) {
      object[key] = '';
    }
    if (object.hasOwnProperty(key) && typeof object[key] === 'object') {
      replaceUndefinedValues(object[key]);
    }
  }

  return object;
};

export const objectToArrayMap = (object, fun = prop => prop) => {
  const array = [];
  for (const property in object) {
    if (object.hasOwnProperty(property)) {
      array.push(fun(object[property]));
    }
  }
  return array;
};

export const capitalizeWord = word => {
  if (word.length <= 3) {
    return word.toUpperCase();
  } else {
    return `${word.substr(0, 1).toUpperCase()}${word.substr(1).toLowerCase()}`.replace(/-/g, ' ');
  }
};
