import isEmpty from 'lodash/isEmpty';

export const handleFieldErrors = error => {
  const {fieldErrors, message} = error;
  let compositeMessage = message ? message + '\n' : '\n';
  if (fieldErrors && !isEmpty(fieldErrors)) {
    fieldErrors.forEach(fe => (compositeMessage += fe.key + ' : ' + fe.value + '\n'));
  }
  return compositeMessage.trim();
};
