/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-07
 */

/**
 * React Router https://reacttraining.com/react-router/web/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import APP_ROUTES from '../../config/APP_ROUTES';

export const getAppRoutesByAuthorities = authorities => {
  const roles = authorities.map(authority => authority.authority);
  return APP_ROUTES.map((route, idx) => {
    let hasRole = false;
    route.roles.forEach(role => {
      hasRole = hasRole || roles.indexOf(role) !== -1;
    });
    return hasRole ? route : null;
  }).filter(route => route !== null);
};

class AppRoutes extends React.Component {
  state = {
    authorities: [],
    appRoutes: [],
  };

  render() {
    const {appRoutes} = this.state;
    return <Switch>{appRoutes}</Switch>;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authorities !== state.authorities) {
      return {
        authorities: props.authorities,
        appRoutes: getAppRoutesByAuthorities(props.authorities).map((route, idx) => {
          let {path, key, exact, redirect, component} = route;
          path = path || null;
          key = key || path || idx;
          exact = exact || false;
          return redirect ? (
            <Redirect key={key} from={path} exact={exact} to={redirect} />
          ) : (
            <Route key={key} path={path} exact={exact} component={component} />
          );
        }),
      };
    }
    return null;
  }
}

AppRoutes.propTypes = {
  authorities: PropTypes.arrayOf(
    PropTypes.shape({
      authority: PropTypes.string.isRequired,
    })
  ),
};

export default withRouter(
  connect(state => ({
    authorities: state.appUser.authorities,
  }))(AppRoutes)
);
