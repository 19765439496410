/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-24
 */

import api from '../../api';
import {
  incompleteBillingInfo,
  userUnverified,
  accountFrozen,
} from '../../config/MESSAGE_AREA_MESSAGES';
import {addMessageAreaContent, removeFooterContent} from './messageArea';
import {getAppUserClientUsageRate} from './appUserPricingPlan';
import {setFormLoader} from './formLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import {handleFieldErrors} from '../../utils/error';
import {replaceNullValuesWithEmptyStrings} from '../../utils/utils';

//feature
export const USER_CLIENT = '[User client]';

//types
export const FETCH_USER_CLIENT = `${USER_CLIENT} FETCH`;
export const FETCH_USER_CLIENT_SUSPENSION_REASON = `${USER_CLIENT} FETCH_SUSPENSION_REASON`;
export const SET_USER_CLIENT = `${USER_CLIENT} SET`;
export const CLEAR_USER_CLIENT = `${USER_CLIENT} CLEAR`;
export const CANCEL_USER_CLIENT_ACCOUNT = `${USER_CLIENT} DELETE`;
export const REINSTATE_USER_CLIENT_ACCOUNT = `${USER_CLIENT} REINSTATE`;
export const SUSPEND_USER_CLIENT = `${USER_CLIENT} SUSPEND`;
export const UNSUSPEND_USER_CLIENT = `${USER_CLIENT} UNSUSPEND`;
export const CHANGE_USER_CLIENT_OWNER = `${USER_CLIENT} CHANGE_OWNER`;

//creators
export const fetchUserClient = () => ({
  type: FETCH_USER_CLIENT,
});
export const fetchUserClientSuspension = () => ({
  type: FETCH_USER_CLIENT_SUSPENSION_REASON,
});
export const setUserClient = userClientData => ({
  type: SET_USER_CLIENT,
  payload: userClientData,
});
export const clearUserClient = () => ({type: CLEAR_USER_CLIENT});
export const cancelAccount = () => ({type: CANCEL_USER_CLIENT_ACCOUNT});
export const reinstateAccount = () => ({type: REINSTATE_USER_CLIENT_ACCOUNT});
export const setUserClientAsSuspended = reason => ({
  type: SUSPEND_USER_CLIENT,
  payload: reason,
});
export const setUserClientAsReactivated = () => ({
  type: UNSUSPEND_USER_CLIENT,
});
export const setUserClientOwner = owner => ({
  type: CHANGE_USER_CLIENT_OWNER,
  payload: owner,
});

//thunks
export const fetchAppUserClient = () => {
  return async dispatch => {
    dispatch(fetchUserClient());
    dispatch(setFormLoader(true, USER_CLIENT));
    await api.auth
      .getSessionClient()
      .then(response => {
        dispatch(setUserClient(response));
        dispatch(getAppUserClientUsageRate());
        const requiredFields = {
          name: response.name,
          address: response.address,
          registerCode: response.registerCode,
          contactPersonName: response.contactPersonName,
          contactPersonPhone: response.contactPersonPhone,
          billingEmail: response.billingEmail,
        };
        if (response.status === 'UNVERIFIED') {
          dispatch(
            addMessageAreaContent(userUnverified.content, userUnverified.priority, USER_CLIENT)
          );
        }
        if (response.status === 'SUSPENDED') {
          dispatch(
            addMessageAreaContent(accountFrozen.content, accountFrozen.priority, USER_CLIENT)
          );
        }
        if (response.status === 'ACTIVE' && Object.values(requiredFields).some(val => !val)) {
          dispatch(
            addMessageAreaContent(
              incompleteBillingInfo.content,
              incompleteBillingInfo.priority,
              USER_CLIENT
            )
          );
        }
        if (response.status === 'ACTIVE') {
          dispatch(removeFooterContent(userUnverified.priority, USER_CLIENT));
          dispatch(removeFooterContent(accountFrozen.priority, USER_CLIENT));
        }
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_CLIENT));
      });
    dispatch(setFormLoader(false, USER_CLIENT));
  };
};

export const updateAppUserClient = data => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER_CLIENT));
    const {
      name,
      address,
      registerCode,
      contactPersonName,
      contactPersonPhone,
      billingEmail,
      vatNumber,
    } = data;
    await api.clients
      .updateSessionClient(
        replaceNullValuesWithEmptyStrings({
          name,
          address,
          registerCode,
          contactPersonName,
          contactPersonPhone,
          billingEmail,
          vatNumber,
        })
      )
      .then(response => {
        dispatch(setUserClient(response));
        dispatch(setToastSuccess({message: 'Changes saved'}, USER_CLIENT));
        if (response.status === 'ACTIVE') {
          dispatch(removeFooterContent(userUnverified.priority, USER_CLIENT));
          dispatch(removeFooterContent(accountFrozen.priority, USER_CLIENT));
        }
        const requiredFields = {
          name: response.name,
          address: response.address,
          registerCode: response.registerCode,
          contactPersonName: response.contactPersonName,
          contactPersonPhone: response.contactPersonPhone,
          billingEmail: response.billingEmail,
        };
        if (Object.values(requiredFields).every(val => !!val)) {
          dispatch(removeFooterContent(incompleteBillingInfo.priority, USER_CLIENT));
        }
      })
      .catch(error => {
        dispatch(setToastError({message: handleFieldErrors(error)}, USER_CLIENT));
      });
    dispatch(setFormLoader(false, USER_CLIENT));
  };
};

export const setUserToBeDeletedStatus = toBeDeleted => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER_CLIENT));

    if (toBeDeleted) {
      await api.clients
        .setToBeDeletedTrue()
        .then(response => {
          dispatch(cancelAccount());
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, USER_CLIENT));
        });
    } else {
      await api.clients
        .setToBeDeletedFalse()
        .then(response => {
          dispatch(reinstateAccount());
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, USER_CLIENT));
        });
    }

    dispatch(setFormLoader(false, USER_CLIENT));
  };
};

export const suspendUserClient = (clientId, reason) => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER_CLIENT));
    await api.clients
      .suspendClient(clientId, reason)
      .then(() => {
        dispatch(setUserClientAsSuspended(reason));
        setTimeout(
          () => dispatch(setToastSuccess({message: 'Client suspended'}, USER_CLIENT)),
          100
        );
        dispatch(addMessageAreaContent(accountFrozen.content, accountFrozen.priority, USER_CLIENT));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_CLIENT));
      });
    dispatch(setFormLoader(false, USER_CLIENT));
    return reason;
  };
};

export const unSuspendUserClient = (clientId, reason) => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER_CLIENT));
    await api.clients
      .reinstateClient(clientId, reason)
      .then(() => {
        dispatch(setUserClientAsReactivated());
        setTimeout(
          () => dispatch(setToastSuccess({message: 'Client reinstated'}, USER_CLIENT)),
          100
        );
        dispatch(removeFooterContent(accountFrozen.priority, USER_CLIENT));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_CLIENT));
      });
    dispatch(setFormLoader(false, USER_CLIENT));
    return reason;
  };
};

export const fetchUserClientSuspensionReason = () => {
  return async dispatch => {
    dispatch(fetchUserClientSuspension());
    dispatch(setFormLoader(true, USER_CLIENT));
    await api.clients
      .getOwnSuspensionReason()
      .then(response => {
        dispatch(setUserClientAsSuspended(response[0].suspensionReason));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_CLIENT));
      });
    dispatch(setFormLoader(false, USER_CLIENT));
  };
};

export const changeUserClientOwner = (clientId, owner) => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER_CLIENT));
    await api.clients
      .changeClientOwner(clientId, owner)
      .then(() => {
        dispatch(setUserClientOwner(owner));
        setTimeout(
          () => dispatch(setToastSuccess({message: 'Client owner changed'}, USER_CLIENT)),
          100
        );
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER_CLIENT));
      });
    dispatch(setFormLoader(false, USER_CLIENT));
    return owner;
  };
};
