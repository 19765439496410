/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-23
 */
import {generateID} from '../../utils/utils';

//types
export const SET_TOAST = 'SET TOAST';
export const SET_TOAST_ERROR = 'SET TOAST ERROR';
export const SET_TOAST_SUCCESS = 'SET TOAST SUCCESS';
export const SET_TOAST_INFO = 'SET TOAST INFO';
export const CLEAR_TOAST = 'TOAST CLEAR';

//creators
const setToast = ({data, feature, type, featureType}) => ({
  type: `${feature} ${featureType}`,
  payload: {...data, type, id: generateID()},
  meta: {feature},
});
export const setToastError = (data, feature) =>
  setToast({data, feature, featureType: SET_TOAST_ERROR, type: 'error'});
export const setToastSuccess = (data, feature) =>
  setToast({data, feature, featureType: SET_TOAST_SUCCESS, type: 'success'});
export const setToastInfo = (data, feature) =>
  setToast({data, feature, featureType: SET_TOAST_INFO, type: 'info'});
export const clearToast = feature => ({
  type: `${feature} ${CLEAR_TOAST}`,
});
