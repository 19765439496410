import {CLEAR_REGIONS_AND_VENDORS, SET_REGIONS_AND_VENDORS} from '../actions/regionsAndVendors';
import {RESET_ALL} from '../constants';

const initialState = {};

export default function regionsAndVendors(state = initialState, action = {}) {
  switch (action.type) {
    case SET_REGIONS_AND_VENDORS:
      let data = action.payload;
      data.regions = ['default'].concat(data.regions);
      return data;
    case CLEAR_REGIONS_AND_VENDORS:
    case RESET_ALL: {
      return initialState;
    }
    default:
      return state;
  }
}
