/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-09
 */

import api from '../api';

/**
 * sign-up-controller : Sign Up Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/sign45up45controller
 */

const signUp = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/sign45up45controller/addClientUsingPOST
   */
  addClient: data => api.post('/api/1/signup', data),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/sign45up45controller/tokenExistsUsingGET
   */
  tokenExists: token => api.get('/api/1/signup/{signUpToken}'.replace(/{signUpToken}/, token)),
};

export default signUp;
