/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-09
 */

import api from '../api';

/**
 * user-controller : User Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/user45controller
 */

const user = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/getAllUsersUsingGET
   */
  getAllUsers: () => api.get('/api/1/user'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/deleteUserByIdUsingDELETE
   */
  deleteUserById: userId => api.delete('/api/1/user/{userId}'.replace(/{userId}/, userId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/getUserUsingGET
   */
  getUser: userId => api.get('/api/1/user/{userId}'.replace(/{userId}/, userId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/changePasswordUsingPUT
   * {"password": "string", "previousPassword": "string"}
   */
  changePassword: (userId, previousPassword, password) =>
    api.put('/api/1/user/{userId}/password'.replace(/{userId}/, userId), {
      previousPassword,
      password,
    }),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/changeUserUsernameUsingPUT
   * {"username": "string"}
   */
  changeUserUsername: (userId, username) =>
    api.put('/api/1/user/{userId}/username'.replace(/{userId}/, userId), {username}),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/changeUserFullNameUsingPUT
   * {"fullName":"string"}
   */
  changeUserFullName: (userId, fullName) =>
    api.put('/api/1/user/{userId}/full_name'.replace(/{userId}/, userId), {fullName}),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/user45controller/changeUserAuthoritiesUsingPUT
   *
   *
   */
  changeUserAuthorities: (userId, authorities) =>
    api.put('/api/1/user/{userId}/authorities'.replace(/{userId}/, userId), {authorities}),
};

export default user;
