/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-22
 */

import {RESET_ALL} from '../constants';
import {
  SET_CLIENT_DETAILS,
  SET_CLIENT_EMAIL,
  SET_CLIENT_AUTHORITIES,
  CLEAR_CLIENT_DETAILS,
  REINSTATE_CLIENT_ACCOUNT,
  CANCEL_CLIENT_ACCOUNT,
  SUSPEND_CLIENT,
  UNSUSPEND_CLIENT,
  CHANGE_CLIENT_OWNER,
} from '../actions/clientDetails';

const initialState = {
  id: null,
  name: '',
  email: '', // <-- client.users[0].username
  authorities: null, // <-- client.users[0].authorities
  clientUserId: null, // <-- client.users[0].id
  address: '',
  registerCode: '',
  contactPersonName: '',
  contactPersonPhone: '',
  billingEmail: '',
  createdAt: null,
  lastModified: null,
  status: null,
  users: [],
  apiTokenList: [],
  toBeDeleted: false,
  suspensionReason: '',
  vatNumber: '',
  accountOwner: '',
};

export default function clientDetails(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CLIENT_DETAILS: {
      const {
        id,
        name,
        address,
        registerCode,
        contactPersonName,
        contactPersonPhone,
        billingEmail,
        createdAt,
        lastModified,
        status,
        users,
        apiTokenList,
        toBeDeleted,
        vatNumber,
        accountOwner,
      } = action.payload;
      const email = users[0].username;
      const authorities = users[0].authorities;
      const clientUserId = users[0].id;
      return {
        ...state,
        id,
        name,
        email,
        authorities,
        clientUserId,
        address,
        registerCode,
        contactPersonName,
        contactPersonPhone,
        billingEmail,
        createdAt,
        lastModified,
        status,
        users,
        apiTokenList,
        toBeDeleted,
        vatNumber,
        accountOwner,
      };
    }
    case SET_CLIENT_EMAIL:
      return {...state, email: action.payload};
    case SET_CLIENT_AUTHORITIES:
      return {...state, authorities: action.payload};
    case CANCEL_CLIENT_ACCOUNT:
      return {...state, toBeDeleted: true};
    case REINSTATE_CLIENT_ACCOUNT:
      return {...state, toBeDeleted: false};
    case SUSPEND_CLIENT:
      return {...state, status: 'SUSPENDED', suspensionReason: action.payload};
    case UNSUSPEND_CLIENT:
      return {...state, status: 'ACTIVE', suspensionReason: ''};
    case CHANGE_CLIENT_OWNER:
      return {...state, accountOwner: action.payload};
    case CLEAR_CLIENT_DETAILS:
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}

export const selectClientBillingInfo = state => {
  const {
    name,
    address,
    billingEmail,
    registerCode,
    vatNumber,
    contactPersonName,
    contactPersonPhone,
    status,
  } = state.clientDetails;
  return {
    name,
    address,
    billingEmail,
    registerCode,
    vatNumber,
    contactPersonName,
    contactPersonPhone,
    status,
  };
};

export const selectClientDeletionStatus = state => state.clientDetails.toBeDeleted;

export const selectClientID = state => state.clientDetails.id;

export const selectClientUsername = state => state.clientDetails.email;

export const selectClientUserId = state => state.clientDetails.clientUserId;
