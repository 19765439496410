/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-07
 */

import isEmail from 'validator/lib/isEmail';
import taiPasswordStrength from 'tai-password-strength';
import errorMessages from '../config/ERROR_MESSAGES';

const passwordTester = new taiPasswordStrength.PasswordStrength();
passwordTester.addCommonPasswords(taiPasswordStrength.commonPasswords);
passwordTester.addTrigraphMap(taiPasswordStrength.trigraphs);

export const isValidEmail = email => {
  return isEmail(email);
};

export const testPassword = password => {
  const {
    passwordErrors: {missingGeneral, notLongEnough, notUnique, noLowerCase, noNumbers, noUpperCase},
  } = errorMessages;
  const errors = [];
  let result = password ? passwordTester.check(password) : null;
  if (!result) {
    errors.push(missingGeneral);
  } else {
    if (result.passwordLength < 8) {
      errors.push(notLongEnough);
    }
    if (!result.charsets.number) {
      errors.push(noNumbers);
    }
    if (!result.charsets.lower) {
      errors.push(noLowerCase);
    }
    if (!result.charsets.upper) {
      errors.push(noUpperCase);
    }
    if (!errors.length && result.commonPassword) {
      errors.push(notUnique);
    }
  }

  return errors;
};

export const isValidPassword = password => {
  return testPassword(password).length === 0;
};

/**
 * the phone number functions work on international phone numbers that are prefixed with country code
 * +3727387300
 *
 */

export const sanitizePhoneNumber = phoneNumber => {
  return phoneNumber.replace(/[^0-9]/g, '') === '' ? '' : '+' + phoneNumber.replace(/[^0-9]/g, '');
};

export const isValidPhoneNumber = phoneNumber => {
  if (typeof phoneNumber !== 'string') {
    return false;
  }

  phoneNumber = phoneNumber.replace(/\s/g, '');

  if (phoneNumber.length < 8 || phoneNumber.length > 16) {
    return false;
  }

  if (phoneNumber.charAt(0) !== '+') {
    return false;
  }
  if (!/^\d+$/.test(phoneNumber.substr(1))) {
    return false;
  }
  return true;
};

export const validateClientData = clientData => {
  const {contactPersonPhone, billingEmail} = clientData;
  const {
    inputErrors: {invalidEmail, invalidPhoneNumber},
  } = errorMessages;

  let fieldErrors = [];

  if (!isValidEmail(billingEmail) && billingEmail !== '') {
    fieldErrors.push({
      field: 'billingEmail',
      message: invalidEmail,
    });
  }
  if (!isValidPhoneNumber(contactPersonPhone) && contactPersonPhone !== '') {
    fieldErrors.push({field: 'contactPersonPhone', message: invalidPhoneNumber});
  }
  return fieldErrors;
};
