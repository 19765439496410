/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

import store from './rootReducer';

window.store = store;

export default store;
