/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-29
 */

import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const API_SERVICE_GROUPS = '[API service groups]';

//types
export const FETCH_SERVICE_GROUPS = `${API_SERVICE_GROUPS} FETCH`;
export const SET_SERVICE_GROUPS = `${API_SERVICE_GROUPS} SET`;
export const CLEAR_SERVICE_GROUPS = `${API_SERVICE_GROUPS} CLEAR`;

//creators
export const fetchServiceGroupList = () => ({
  type: FETCH_SERVICE_GROUPS,
});
export const setServiceGroups = serviceGroups => ({
  type: SET_SERVICE_GROUPS,
  payload: serviceGroups,
});

//thunks
export const fetchApiServiceGroupList = () => {
  return async dispatch => {
    dispatch(fetchServiceGroupList());
    dispatch(setLoader(true, API_SERVICE_GROUPS));
    await api.services
      .getApiServiceGroupList()
      .then(response => {
        dispatch(setServiceGroups(response));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, API_SERVICE_GROUPS));
      });
    dispatch(setLoader(false, API_SERVICE_GROUPS));
  };
};
