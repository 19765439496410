import {RESET_ALL} from '../constants';
import {SET_TOKEN_LIST, CLEAR_TOKEN_LIST} from '../actions/tokenList';

const initialState = [
  /* we want to hold an array like this
 [
   {
    "id": 1,
    "clientId:10,
    "client":name,
    "name": "tokenName",
    "token": "eyJhbGciOiJIUzI1NiJ9...etc",
    "apiGroups": [],
    "createdAt": 1526907810278,
    "lastModified": 1526907896958,
    "status": "ENABLED"
   },
  ...
 ]
  */
];

/* getClientList API call produces a response like that
[
{
  id: 10,
  name: "name",
  email: "email@email",
  address:  "address",
  createdAt: 1520516359385,
  lastModified: 1520516359385,
  status: "ACTIVE",
  users: [],
  apiTokenList: [
    {"id": 1,
    "name": "tokenName",
    "token": "eyJhbGciOiJIUzI1NiJ9...etc",
    "apiGroups": [],
    "createdAt": 1526907810278,
    "lastModified": 1526907896958,
    "status": "ENABLED"]},
    ...
},
...
]
*/

export default function tokenList(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TOKEN_LIST:
      return action.payload;
    case CLEAR_TOKEN_LIST:
    case RESET_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
