/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-07
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AppRoutes from './AppRoutes';
import Footer from './footer/ConstantFooter';
import {
  FOOTER_HEIGHT,
  FULL_WIDTH_CONTENT_1600px,
  HEADER_HEIGHT,
  MAIN_HEADING_MARGIN,
  MESSAGE_AREA_HEIGHT,
  MIN_WIDTH_FOR_CONTENT_480px,
} from '../../config/LAYOUT';
import MessageArea from './MessageArea';

const Body = styled.div`
  position: absolute;
  top: ${({loggedIn}) => (loggedIn ? HEADER_HEIGHT : '0')}px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Container = styled.div`
  min-height: calc(
    100vh -
      ${({showMessageArea, loggedIn}) => {
        if (!loggedIn) {
          return FOOTER_HEIGHT;
        }
        if (showMessageArea) {
          return FOOTER_HEIGHT + HEADER_HEIGHT + MAIN_HEADING_MARGIN;
        }
        return FOOTER_HEIGHT + HEADER_HEIGHT + MAIN_HEADING_MARGIN;
      }}px
  );
  max-width: ${FULL_WIDTH_CONTENT_1600px};
  min-width: ${MIN_WIDTH_FOR_CONTENT_480px};
  margin: 0 auto;

  h1 {
    margin: ${({showMessageArea, loggedIn}) => {
      if (loggedIn && showMessageArea) {
        return MAIN_HEADING_MARGIN - MESSAGE_AREA_HEIGHT + 'px 0 32px 0 !important';
      }
      if (loggedIn) {
        return MAIN_HEADING_MARGIN + 'px 0 32px 0 !important';
      }
    }};
  
`;

const AppContent = ({messageAreaVisible, loggedIn}) => {
  return (
    <Body showMessageArea={messageAreaVisible} loggedIn={loggedIn}>
      <MessageArea />

      <Container showMessageArea={messageAreaVisible} loggedIn={loggedIn}>
        <AppRoutes />
      </Container>
      <Footer />
    </Body>
  );
};

AppContent.propTypes = {
  messageAreaVisible: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default AppContent;
