/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

/**
 * If user has logged in without 'Remember my login on this computer' checked,
 * he will be logged out automatically after 'sessionMaxAgeSeconds' of idle time.
 * Prior to killing the session, a dialog will be displayed for 'displayMessageForSeconds',
 * where user can either continue session or end it.
 * If user won't take any action, then after the countdown has reached zero,
 * user will be logged out and new dialog will inform him about it.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getAuthorizationTokenFromLocalStorage} from '../../api/authorizationToken';
import {appUserSignOut} from '../../store/actions/appUser';
import {Modal} from 'semantic-ui-react';
import {Button} from './semanticElements';
import styled from 'styled-components';

const SessionWillExpireContent = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-top: 68px;
  margin-bottom: 115px;
  p {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 48px;
    text-align: center !important;
  }
  div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  button {
    margin-right: 32px !important;
  }
`;

const SessionExpiredContent = styled.div`
  width: 50%;
  margin: 68px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    font-family: Lato;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  }
  p {
    font-size: 24px;
    margin-bottom: 48px;
    text-align: center !important;
  }
`;

const sessionMaxAgeSeconds = 3600;
const displayMessageForSeconds = 60;

class SessionExpiresMessage extends React.Component {
  state = {
    showSessionExpiresMessage: false,
    showSessionExpiredMessage: false,
    expiresCountdown: displayMessageForSeconds,
  };

  timeoutId = null;

  render() {
    const {showSessionExpiresMessage, showSessionExpiredMessage, expiresCountdown} = this.state;

    if (showSessionExpiresMessage) {
      return (
        <Modal open={true}>
          <SessionWillExpireContent>
            <p>{`Your session will expire in ${expiresCountdown} seconds`}</p>
            <div>
              <Button
                ghost
                onClick={() => {
                  this.setState({showSessionExpiresMessage: false});
                  clearTimeout(this.timeoutId);
                  this.props.appUserSignOut();
                }}>
                End session
              </Button>
              <Button
                onClick={() => {
                  this.setState({showSessionExpiresMessage: false});
                  this.resetSessionTicker();
                }}>
                Continue session
              </Button>
            </div>
          </SessionWillExpireContent>
        </Modal>
      );
    }
    if (showSessionExpiredMessage) {
      return (
        <Modal open={true}>
          <SessionExpiredContent>
            <h2>Your session has expired</h2>
            <p>Please sign in to continue</p>
            <Button onClick={() => this.setState({showSessionExpiredMessage: false})}>
              Sign in
            </Button>
          </SessionExpiredContent>
        </Modal>
      );
    }

    return null;
  }

  componentDidMount() {
    this.startSessionTicker();
  }

  componentWillUnmount() {
    this.stopSessionTicker();
  }

  componentDidUpdate(prevProps) {
    if (this.props.appUserId !== prevProps.appUserId) {
      if (this.props.appUserId) {
        this.startSessionTicker();
      } else {
        this.stopSessionTicker();
      }
    } else if (this.initialized && this.props.location !== prevProps.location) {
      this.resetSessionTicker();
    }
  }

  startSessionTicker = () => {
    if (this.initialized) {
      return;
    }
    if (this.props.appUserId && !getAuthorizationTokenFromLocalStorage()) {
      this.initialized = true;
      document.addEventListener('click', this.resetSessionTicker);
      this.resetSessionTicker();
    }
  };

  stopSessionTicker = () => {
    if (!this.initialized) {
      return;
    }
    this.initialized = false;
    document.removeEventListener('click', this.resetSessionTicker);
    clearTimeout(this.timeoutId);
  };

  resetSessionTicker = () => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.showSessionExpiresMessage, sessionMaxAgeSeconds * 1000);
  };

  showSessionExpiresMessage = () => {
    this.setState({showSessionExpiresMessage: true, expiresCountdown: displayMessageForSeconds});
    this.timeoutId = setInterval(() => {
      let {expiresCountdown} = this.state;
      expiresCountdown -= 1;
      if (expiresCountdown > 0) {
        this.setState({expiresCountdown});
      } else {
        clearTimeout(this.timeoutId);
        this.props.appUserSignOut();
        this.setState({showSessionExpiresMessage: false, showSessionExpiredMessage: true});
      }
    }, 1000);
  };
}

SessionExpiresMessage.propTypes = {
  appUserId: PropTypes.number,
  appUserSignOut: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    state => ({appUserId: state.appUser.id}),
    {appUserSignOut}
  )(SessionExpiresMessage)
);
