/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-24
 */
import {RESET_ALL} from '../constants';
import {SET_USER, SET_USERNAME, SET_FULLNAME, CLEAR_USER} from '../actions/appUser';
let initialState = {
  id: null,
  username: null,
  fullName: null,
  status: null,
  authorities: [{authority: 'GUEST'}],
  lastModified: null,
};

export default function appUser(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER: {
      const {id, username, fullName, status, authorities, lastModified} = action.payload;
      return {id, username, fullName, status, authorities, lastModified};
    }

    case SET_USERNAME: {
      const {username, status} = action.payload;
      return {...state, username, status};
    }

    case SET_FULLNAME: {
      const {fullName} = action.payload;
      return {...state, fullName};
    }

    case CLEAR_USER:
    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}

export const isAdmin = state => {
  return state.appUser.authorities.some(a => a.authority === 'ADMIN');
};
