/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-22
 */
import {RESET_ALL} from '../constants';
import {SET_CLIENT_LIST, CLEAR_CLIENT_LIST} from '../actions/clientList';

const initialState = [
  /*
  {
    id: 1,
    name: "name",
    email: "email@email",   // <-- client.users[0].username
    address:  "address",
    createdAt: 1520516359385,
    lastModified: 1520516359385,
    status: "ACTIVE",
    users: [],
    apiTokenList: []
  },
  ...
  */
];

export default function clientList(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CLIENT_LIST:
      return action.payload;
    case CLEAR_CLIENT_LIST:
    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
}
