/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-29
 */

import {RESET_ALL} from '../constants';
import {SET_SERVICE_GROUPS, CLEAR_SERVICE_GROUPS} from '../actions/apiServiceGroupList';

const initialState = [
  /*
  {
    "id": "98822145-af8f-42b7-bb16-44f3a7c437ce",
    "name": "GEOCODE",
    "apiGroups":[
      {
      id: "jgc_rest",
      name: "jgc_rest"
       },...
     ]
  },
  ...
   */
];

export default function apiServiceGroupList(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SERVICE_GROUPS:
      return action.payload;
    case CLEAR_SERVICE_GROUPS:
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
