import api from '../api';

const vendors = {
  /**
   * swagger-ui.html#!/configuration45controller/findByServiceTokenIdUsingGET
   * ADMIN & USER - returns array containing objects {region:"regionNameString", vendor:"vendorNameString"}
   * containing region+vendor combinations that are defined to given service token (identified by id)
   */
  findVendorsByServiceTokenId: id => api.get(`/api/1/conf/data_vendor/${id}`),

  /**
   * /swagger-ui.html#!/configuration45controller/saveUsingPOST
   * ADMIN & USER - given region+vendor combination from object {region:"regionNameString", vendor:"vendorNameString"}
   * is saved to token with given ID
   */
  saveVendorToServiceToken: (arr, id) => api.post(`/api/1/conf/data_vendor/${id}`, arr),

  /**
   * /swagger-ui.html#!/configuration45controller/getRegionsAndVendorsUsingGET
   * ADMIN & USER returns object containing string arrays for all available regions and vendors
   * {regions:[],vendors:[]}
   */
  getRegionsAndVendors: () => api.get('/api/1/conf/regions_vendors'),
};

export default vendors;
