/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-09
 */

import api from '../api';

/**
 * clients-controller : Clients Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/clients45controller
 */
//prettier-ignore
const clients = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/getAllClientsUsingGET
   * ADMIN - return array with all clients
   */
  getAllClients: () => api.get('/api/1/client'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/createClientUsingPOST
   * ADMIN - create new client
   */
  createClient: data => api.post('/api/1/client', data),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/updateSessionClientUsingPUT
   * ADMIN & USER - update your own client tokenList
   */
  updateSessionClient: data => api.put('/api/1/client', data),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/deleteClientUsingDELETE
   * ADMIN - delete client
   */
  deleteClient: clientId => api.delete('/api/1/client/{clientId}'.replace(/{clientId}/, clientId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/getClientUsingGET
   * ADMIN - get one client
   */
  getClient: clientId => api.get('/api/1/client/{clientId}'.replace(/{clientId}/, clientId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/updateClientUsingPUT
   * ADMIN - update client
   */
  updateClient: data => api.put('/api/1/client/{clientId}'.replace(/{clientId}/, data.id), data),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/optInUsingPUT_1
   */
  optIn: () => api.put('/api/1/client/opt_in'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/optInUsingPUT
   */
  optClientIn: clientId =>
    api.put('/api/1/client/opt_in/{clientId}'.replace(/{clientId}/, clientId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/optOutUsingPUT_1
   */
  optOut: () => api.put('/api/1/client/opt_out'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/clients45controller/optOutUsingPUT
   */
  optClientOut: clientId =>
    api.put('/api/1/client/opt_out/{clientId}'.replace(/{clientId}/, clientId)),

  /**
   *
   https://bgm.reach-u.com/bgm-portal-api/swagger-ui.html#!/clients45controller/setToBeDeletedStatusTrueUsingPUT_1
   */
  setToBeDeletedTrue: () => api.put('/api/1/client/deletion_state_true'),

  /**
   *
   https://bgm.reach-u.com/bgm-portal-api/swagger-ui.html#!/clients45controller/setToBeDeletedStatusFalseUsingPUT_1
   */
  setToBeDeletedFalse: () => api.put('/api/1/client/deletion_state_false'),

  /**
   *
   https://bgm.reach-u.com/bgm-portal-api/swagger-ui.html#!/clients45controller/setToBeDeletedStatusTrueUsingPUT
   */
  setClientToBeDeletedTrue: clientId => api.put(`/api/1/client/deletion_state_true/${clientId}`),

  /**
   *
   https://bgm.reach-u.com/bgm-portal-api/swagger-ui.html#!/clients45controller/setToBeDeletedStatusFalseUsingPUT
   */
  setClientToBeDeletedFalse: clientId => api.put(`/api/1/client/deletion_state_false/${clientId}`),

  suspendClient: (clientId, reason) => api.put(`/api/1/client/suspend/${clientId}`, {reason: reason}),

  reinstateClient: (clientId, reason) => api.put(`/api/1/client/reinstate/${clientId}`, {reason: reason}),

  getClientSuspensionReason: clientId => api.get(`/api/1/client/suspension/${clientId}`),

  getOwnSuspensionReason: () => api.get('/api/1/client/suspension/'),

  changeClientOwner: (clientId,owner) => api.put(`/api/1/client/owner/${clientId}/${owner}`),

};

export default clients;
