export const IS_BETA = true;

export const dateFormat = 'YYYY-MM-DD';

export const timeFormat = 'HH:mm:ss';

export const datetimeFormat = 'YYYY-MM-DD, HH:mm:ss';

export const dateStringFormats = [
  {
    type: null,
    format: 'DD MMM HH:mm:ss.S',
    displayFormat: 'DD MMM HH:mm',
  },
  {
    type: 'HOUR',
    format: 'DD MMM HH:mm',
  },
  {
    type: 'DAY',
    format: 'DD MMM',
  },
  {
    type: 'MONTH',
    format: "MMM 'YY",
  },
];

export const statisticsAPIObject = {
  service: 'apiName',
  startedAt: 'startedAt',
  client: 'clientId',
  count: 'count',
  latency: 'reqLatency',
  responseStatus: 'responseStatus',
  token: 'tokenId',
  objectId: 'id',
};

export const groupNames = [
  {
    value: statisticsAPIObject.service,
    display: 'Services',
    displaySingle: 'Service',
    iconName: 'server',
  },
  {
    value: statisticsAPIObject.token,
    display: 'API keys',
    displaySingle: 'API key',
    iconName: 'key',
  },
  {
    value: statisticsAPIObject.client,
    display: 'Clients',
    displaySingle: 'Client',
    iconName: 'user',
  },
  {
    value: statisticsAPIObject.responseStatus,
    display: 'Status codes',
    displaySingle: 'Status code',
    iconName: 'code',
  },
];

export const maxTableRows = [50, 100, 200];

export const maxRequestSize = 1000;

export const chartTypes = {
  area: 'AREA',
  areaWithTotal: 'AREA_WITH_TOTAL',
  table: 'TABLE',
  line: 'LINE',
};

export const chartColors = {
  wms: '#ABDDA4',
  geocoder: '#FDAE61',
  tms: '#FFEAAA',
  'reverse-geocoder': '#F46D43',
  'address-components': '#5E6AA1',
  address: '#5E6AA1',
  Unknown: '#3288BD',
  'points-of-interest': '#FEE08B',
  routing: '#43AA8B',
  geocode: '#B2B09B',
  poi: '#AAC0AA',
  'reverse-geocode': '#CBE896',
};

export const toastMessages = {
  apiKeyCopied: 'API copied to clipboard',
};

export const userStatuses = {
  ACTIVE: 'ACTIVE',
  UNVERIFIED: 'UNVERIFIED',
};
