/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-22
 */

//types
export const SET_LOADER = 'SET LOADER';

//creators
export const setLoader = (loaderValue, feature) => ({
  type: `${feature} ${SET_LOADER} ${loaderValue.toString().toUpperCase()}`,
  payload: loaderValue,
  meta: {feature},
});
