/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-05-31
 */

import {RESET_ALL} from '../constants';
import {statisticsAPIObject} from '../../config/CONSTANTS';
import {SET_STATISTICS, CLEAR_STATISTICS} from '../actions/statistics';

const initialState = {
  statistics: [],
  compareStatistics: [],
  totalRequests: 0,
  trend: {},
  totals: [
    {
      group: statisticsAPIObject.service,
      total: 0,
    },
    {
      group: statisticsAPIObject.client,
      total: 0,
    },
    {
      group: statisticsAPIObject.token,
      total: 0,
    },
  ],
  totalResponseElements: 0,
  totalTablePages: 0,
};

export default function statistics(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STATISTICS:
      return {...state, ...action.payload};
    case CLEAR_STATISTICS:
      return {...state, statistics: []};
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
