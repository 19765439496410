/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-11
 */

import api from '../../api';

import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const INVOICE_LIST = '[Invoice list]';

//types
export const FETCH_INVOICE_LIST = `${INVOICE_LIST} FETCH`;
export const SET_INVOICE_LIST = `${INVOICE_LIST} SET`;
export const FETCH_INVOICE_PDF = `${INVOICE_LIST} FETCH PDF`;
export const UPDATE_INVOICE_LIST = `${INVOICE_LIST} UPDATE`;
export const CLEAR_INVOICE_LIST = `${INVOICE_LIST} CLEAR`;

//creators
export const _fetchInvoiceList = () => ({
  type: FETCH_INVOICE_LIST,
});
export const setInvoiceList = invoiceList => ({
  type: SET_INVOICE_LIST,
  payload: invoiceList,
});
export const fetchInvoicePdf = () => ({
  type: FETCH_INVOICE_PDF,
});
export const updateInvoiceList = () => ({
  type: UPDATE_INVOICE_LIST,
});

//thunks
export const fetchInvoiceList = () => {
  return async dispatch => {
    dispatch(_fetchInvoiceList());
    dispatch(setLoader(true, INVOICE_LIST));
    await api.invoice
      .findAll()
      .then(response => {
        dispatch(setInvoiceList(response));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, INVOICE_LIST));
  };
};

export const fetchInvoiceListForClient = clientId => {
  return async dispatch => {
    dispatch(_fetchInvoiceList());
    dispatch(setLoader(true, INVOICE_LIST));
    await api.invoice
      .findAllByClientId(clientId)
      .then(response => {
        dispatch(setInvoiceList(response));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, INVOICE_LIST));
  };
};

export const downloadInvoicePdf = filename => {
  return async dispatch => {
    dispatch(setLoader(true, INVOICE_LIST));
    await api.invoice
      .findFile(filename)
      .then(response => {
        const blob = new Blob([response], {type: 'octet/stream'});
        const url = (window.URL || window.webkitURL).createObjectURL(blob);
        const link = document.createElement('A');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          (window.URL || window.webkitURL).revokeObjectURL(url);
          document.body.removeChild(link);
        }, 500);
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });
    dispatch(setLoader(false, INVOICE_LIST));
  };
};

export const updateInvoiceStatus = (id, status, clientId) => {
  return async dispatch => {
    dispatch(updateInvoiceList());
    dispatch(setLoader(true, INVOICE_LIST));
    let invoice = null;
    await api.invoice
      .updateInvoiceStatus(id, status)
      .then(response => {
        invoice = response;
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}));
      });

    if (invoice && clientId) {
      await api.invoice
        .findAllByClientId(clientId)
        .then(response => {
          dispatch(setInvoiceList(response));
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}));
        });
    }

    if (invoice && !clientId) {
      await api.invoice
        .findAll()
        .then(response => {
          dispatch(setInvoiceList(response));
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}));
        });
    }
    dispatch(setLoader(false, INVOICE_LIST));
  };
};
