/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-24
 */

import api from '../../api';
import {
  getAuthorizationToken,
  setAuthorizationToken,
  unsetAuthorizationToken,
} from '../../api/authorizationToken';
import {isValidEmail, isValidPassword, testPassword} from '../../utils/validation';
import {clearUserClient, fetchAppUserClient, setUserClient} from './appUserClient';
import {resetAll} from '../constants';
import {setLoader} from './appLoader';
import {setFormLoader} from './formLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import errorMessages from '../../config/ERROR_MESSAGES';

//feature
export const USER = '[User]';

//types
export const FETCH_USER = `${USER} FETCH`;
export const SET_USER = `${USER} SET`;
export const SET_USERNAME = `${USER} SET USERNAME`;
export const SET_FULLNAME = `${USER} SET FULLNAME`;
export const CLEAR_USER = `${USER} CLEAR`;

//creators
export const fetchUser = () => ({
  type: FETCH_USER,
});
export const setUser = userData => ({
  type: SET_USER,
  payload: userData,
});
export const setUsername = ({username, status}) => ({
  type: SET_USERNAME,
  payload: {username, status},
});
export const setFullName = fullName => ({
  type: SET_FULLNAME,
  payload: fullName,
});
export const clearUser = () => ({type: CLEAR_USER});

//thunks
export const appUserAuth = () => {
  return async dispatch => {
    let isAuthorized = false;
    const token = getAuthorizationToken();
    if (!token) {
      dispatch(clearUser());
      dispatch(clearUserClient());
      return null;
    }
    dispatch(fetchUser());
    dispatch(setLoader(true, USER));
    await api.auth
      .getUserByToken()
      .then(response => {
        dispatch(setUser(response));
        dispatch(fetchAppUserClient());
        isAuthorized = true;
      })
      .catch(() => {
        unsetAuthorizationToken();
        dispatch(clearUser());
        dispatch(clearUserClient());
      });
    dispatch(setLoader(false, USER));
    return isAuthorized;
  };
};

export const appUserSignIn = (username, password, rememberMyLogin) => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER));
    await api.auth
      .generateToken(username, password)
      .then(response => {
        setAuthorizationToken(response.token, rememberMyLogin);
        dispatch(appUserAuth());
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER));
      });
    dispatch(setFormLoader(false, USER));
  };
};

export const appUserSignOut = () => {
  return async dispatch => {
    dispatch(setLoader(true, USER));
    await api.auth
      .invalidateToken()
      .then(() => {
        unsetAuthorizationToken();
        dispatch(resetAll());
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER));
      });
    dispatch(setLoader(false, USER));
  };
};

export const appUserSignUp = appUserData => dispatch => {
  dispatch(setFormLoader(true, USER));
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const {username, password, passwordRepeat} = appUserData;
      const {
        passwordErrors: {missingGeneral, missingRepeat, notMatching},
        inputErrors: {missingEmail, invalidEmail},
      } = errorMessages;
      const errors = {
        username: '',
        password: '',
        passwordRepeat: '',
      };
      if (!username) {
        errors.username = missingEmail;
      } else if (!isValidEmail(username)) {
        errors.username = invalidEmail;
      }
      if (!password) {
        errors.password = missingGeneral;
      } else if (!isValidPassword(password)) {
        errors.password = `<ul><li>${testPassword(password).join('</li><li>')}</li></ul>`;
      }
      if (!passwordRepeat) {
        errors.passwordRepeat = missingRepeat;
      } else if (password !== passwordRepeat) {
        errors.passwordRepeat = notMatching;
      }
      const errorsFound = Object.keys(errors).filter(key => errors[key]);
      if (errorsFound.length) {
        dispatch(setFormLoader(false, USER));
        reject({message: 'Sign up error', errorMessages: errors});
      } else {
        api.signUp
          .addClient({user: {username, password}})
          .then(response => {
            dispatch(setFormLoader(false, USER));
            resolve(response);
          })
          .catch(error => {
            dispatch(setFormLoader(false, USER));
            reject({
              errorMessages: {
                ...errors,
                username: error.message,
              },
            });
          });
      }
    }, 250);
  });
};

export const sendPasswordResetLink = email => dispatch => {
  dispatch(setFormLoader(true, USER));
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const {
        inputErrors: {missingEmail, invalidEmail},
      } = errorMessages;
      let errorMessage = null;
      if (!email) {
        errorMessage = missingEmail;
      } else if (!isValidEmail(email)) {
        errorMessage = invalidEmail;
      }
      if (errorMessage) {
        dispatch(setFormLoader(false, USER));
        reject({message: errorMessage});
      } else {
        api.auth
          .resetUserPassword(email)
          .then(response => {
            dispatch(setFormLoader(false, USER));
            resolve(response);
          })
          .catch(error => {
            dispatch(setFormLoader(false, USER));
            reject(error);
          });
      }
    }, 250);
  });
};

export const validatePasswordResetToken = resetLinkToken => {
  return async dispatch => {
    dispatch(setLoader(true, USER));
    let result = false;
    await api.signUp
      .tokenExists(resetLinkToken)
      .then(response => {
        result = response;
      })
      .catch(() => {});
    dispatch(setLoader(false, USER));
    if (result !== true) {
      throw new Error('Oh no, this password link seems to be either broken or expired...');
    }
  };
};

export const confirmPasswordReset = (resetLinkToken, password, passwordRepeat) => dispatch => {
  dispatch(setFormLoader(true, USER));
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const {
        passwordErrors: {missingGeneral, missingRepeat, notMatching},
      } = errorMessages;
      const errorMessage = {
        password: null,
        passwordRepeat: null,
      };
      if (!password) {
        errorMessage.password = missingGeneral;
      } else if (!isValidPassword(password)) {
        errorMessage.password = `<ul><li>${testPassword(password).join('</li><li>')}</li></ul>`;
      }
      if (!passwordRepeat) {
        errorMessage.passwordRepeat = missingRepeat;
      } else if (password !== passwordRepeat) {
        errorMessage.passwordRepeat = notMatching;
      }

      const errorsFound = Object.keys(errorMessage).filter(key => !!errorMessage[key]).length;
      if (errorsFound) {
        dispatch(setFormLoader(false, USER));
        reject({message: errorMessage});
      } else {
        api.auth
          .confirmPasswordReset(resetLinkToken, password)
          .then(response => {
            dispatch(setFormLoader(false, USER));
            setAuthorizationToken(response.token);
            resolve(response);
          })
          .catch(error => {
            dispatch(setFormLoader(false, USER));
            reject(error);
          });
      }
    }, 250);
  });
};

export const updateAppUserUsername = (id, username) => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER));
    const {
      inputErrors: {missingEmail, invalidEmail},
    } = errorMessages;
    let errorMessage = null;
    if (!username) {
      errorMessage = missingEmail;
    } else if (!isValidEmail(username)) {
      errorMessage = invalidEmail;
    }
    if (errorMessage) {
      dispatch(setToastError({message: errorMessage}, USER));
    } else {
      await api.user
        .changeUserUsername(id, username)
        .then(response => {
          dispatch(setUsername(response));
          dispatch(setToastSuccess({message: 'E-mail address changed'}, USER));
          api.auth.getSessionClient().then(response => {
            dispatch(setUserClient(response));
          });
        })
        .catch(error => {
          dispatch(setToastError({message: error.message}, USER));
        });
    }
    dispatch(setFormLoader(false, USER));
  };
};

export const updateAppUserFullName = (id, fullName) => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER));
    await api.user
      .changeUserFullName(id, fullName)
      .then(response => {
        dispatch(setFullName(response));
        dispatch(
          setToastSuccess(
            {
              message: "User's full name changed",
            },
            USER
          )
        );
        api.auth.getSessionClient().then(response => {
          dispatch(setUserClient(response));
        });
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER));
      });
    dispatch(setFormLoader(false, USER));
  };
};

export const updateAppUserPassword = (id, previousPassword, password) => {
  return async dispatch => {
    let result = false;
    await api.user
      .changePassword(id, previousPassword, password)
      .then(() => {
        dispatch(
          setToastSuccess(
            {
              message: 'Password changed',
            },
            USER
          )
        );
        result = true;
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER));
      });
    return result;
  };
};

export const verifyAppUserEmail = verifyLinkToken => {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      let token = null;
      await api.auth
        .verifyUser(verifyLinkToken)
        .then(response => {
          token = response.token;
        })
        .catch(() => {
          reject();
        });
      if (token) {
        setAuthorizationToken(token);
        const auth = await dispatch(appUserAuth());
        if (auth) {
          resolve();
        } else {
          reject();
        }
      }
    });
  };
};

export const resendEmailVerificationLink = id => {
  return async dispatch => {
    dispatch(setFormLoader(true, USER));
    await api.auth
      .resendVerificationToken(id)
      .then(() => {
        dispatch(setToastSuccess({message: 'Verification email sent'}, USER));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, USER));
      });
    dispatch(setFormLoader(false, USER));
  };
};
