/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-23
 */

import {CLEAR_TOAST, SET_TOAST} from '../actions/toastMessage';

const initialState = {
  message: null,
  statusCode: null,
  type: 'default',
  id: null,
};

export default function toastMessage(state = initialState, action = {}) {
  switch (true) {
    case action.type.includes(SET_TOAST):
      return {...state, ...action.payload};
    case action.type === CLEAR_TOAST:
      return initialState;
    default:
      return state;
  }
}

function selectFromToast(state, key) {
  return state.toastMessage[key];
}

export function selectToastId(state) {
  return selectFromToast(state, 'id');
}

export function selectToastMessage(state) {
  return selectFromToast(state, 'message');
}

export function selectToastStatusCode(state) {
  return selectFromToast(state, 'statusCode');
}

export function selectToastType(state) {
  return selectFromToast(state, 'type');
}
