/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-29
 */

import api from '../../api';
import {setLoader} from './appLoader';
import {setToastError} from './toastMessage';

//feature
export const API_SERVICES = '[API services]';

//types
export const FETCH_API_SERVICE_LIST = `${API_SERVICES} FETCH`;
export const SET_API_SERVICE_LIST = `${API_SERVICES} SET`;
export const CLEAR_API_SERVICE_LIST = `${API_SERVICES} CLEAR`;

//creators
export const fetchServiceList = () => ({
  type: FETCH_API_SERVICE_LIST,
});
export const setServiceList = serviceList => ({
  type: SET_API_SERVICE_LIST,
  payload: serviceList,
});

//thunks
export const fetchApiServiceList = () => {
  return async (dispatch, getState) => {
    if (getState().apiServiceList.length) {
      return;
    }
    dispatch(fetchServiceList());
    dispatch(setLoader(true, API_SERVICES));
    await api.services
      .getApiServiceList()
      .then(response => {
        dispatch(setServiceList(response));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, API_SERVICES));
      });
    dispatch(setLoader(false, API_SERVICES));
  };
};
