/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-19
 */

import api from '../api';

/**
 * pricing-plan-controller : Pricing Plan Controller
 * http://192.168.115.194/bgm-portal-api/swagger-ui.html#/pricing45plan45controller
 */

const pricingPlan = {
  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/findAllUsingGET_2
   */
  findAll: () => api.get('/api/1/pricing_plan'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/findActiveUsingGET_1
   */
  findActive: () => api.get('/api/1/pricing_plan/active'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/findActiveUsingGET
   */
  findClientActive: clientId =>
    api.get('/api/1/pricing_plan/active/{clientId}'.replace(/{clientId}/, clientId)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/changeUsingPOST_1
   */
  change: pricingPlan =>
    api.post('/api/1/pricing_plan/change/{id}'.replace(/{id}/, pricingPlan.id)),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/changeUsingPOST
   */
  changeClientPlan: (clientId, pricingPlan) =>
    api.post(
      '/api/1/pricing_plan/change/{clientId}/{planId}'
        .replace(/{clientId}/, clientId)
        .replace(/{planId}/, pricingPlan.id)
    ),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/findPendingUsingGET_1
   */
  findPending: () => api.get('/api/1/pricing_plan/pending'),

  /**
   * http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/findPendingUsingGET
   */
  findClientPending: clientId =>
    api.get('/api/1/pricing_plan/pending/{clientId}'.replace(/{clientId}/, clientId)),

  /**
   *http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/cancelPendingSubscriptionsUsingDELETE_1
   */
  cancelPending: () => api.delete('/api/1/pricing_plan/cancel_pending'),

  /**
   *http://192.168.115.194/bgm-portal-api/swagger-ui.html#!/pricing45plan45controller/cancelPendingSubscriptionsUsingDELETE
   */
  cancelClientPending: clientId =>
    api.delete('/api/1/pricing_plan/cancel_pending/{clientId}'.replace(/{clientId}/, clientId)),

  getTrial: () => api.get('/api/1/pricing_plan/trial'),

  getClientTrial: clientId => api.get(`/api/1/pricing_plan/trial/${clientId}`),

  startTrial: () => api.post('/api/1/pricing_plan/trial'),

  startClientTrial: clientId => api.post(`/api/1/pricing_plan/trial/${clientId}`),
};

export default pricingPlan;
