/**
 * @author: kaspar.arme@reach-u.com
 * @since: 2018-07-11
 */

import {setLoader} from './appLoader';
import {setToastError, setToastSuccess} from './toastMessage';
import api from '../../api';

//feature
export const CLIENT_PENDING_PLAN = '[Client pending plan]';

//types
export const FETCH_CLIENT_PRICING_PLAN_PENDING = `${CLIENT_PENDING_PLAN} FETCH`;
export const SET_CLIENT_PRICING_PLAN_PENDING = `${CLIENT_PENDING_PLAN} SET`;
export const CLEAR_CLIENT_PENDING_PLAN = `${CLIENT_PENDING_PLAN} CLEAR`;

//creators
export const clientPricingPlanPendingFetch = () => ({
  type: FETCH_CLIENT_PRICING_PLAN_PENDING,
});
export const setClientPricingPlanPending = plan => ({
  type: SET_CLIENT_PRICING_PLAN_PENDING,
  payload: plan,
});
export const clearClientPendingPlan = () => ({
  type: CLEAR_CLIENT_PENDING_PLAN,
});

//thunks
export const fetchClientPendingPricingPlan = clientId => {
  return async dispatch => {
    dispatch(clientPricingPlanPendingFetch());
    dispatch(setLoader(true, CLIENT_PENDING_PLAN));
    await api.pricingPlan
      .findClientPending(clientId)
      .then(response => {
        dispatch(
          setClientPricingPlanPending({
            ...response,
            activationDate: Date.parse(response.activationDate),
          })
        );
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT_PENDING_PLAN));
      });
    dispatch(setLoader(false, CLIENT_PENDING_PLAN));
  };
};

export const cancelClientPendingPricingPlan = clientId => {
  return async dispatch => {
    dispatch(setLoader(true, CLIENT_PENDING_PLAN));
    await api.pricingPlan
      .cancelClientPending(clientId)
      .then(() => {
        dispatch(clearClientPendingPlan());
        dispatch(setToastSuccess({message: 'Pending plan cancelled'}, CLIENT_PENDING_PLAN));
      })
      .catch(error => {
        dispatch(setToastError({message: error.message}, CLIENT_PENDING_PLAN));
      });
    dispatch(setLoader(false, CLIENT_PENDING_PLAN));
  };
};
