/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-10
 */

/**
 * moment > https://momentjs.com/
 */

import moment from 'moment-timezone';

export const formatDateTime = (
  timestamp,
  format = 'YYYY-MM-DD HH:mm:ss',
  timezone = 'Europe/Tallinn'
) => {
  return timestamp
    ? moment(timestamp)
        .tz(timezone)
        .format(format)
    : null;
};

export const formatDate = (timestamp, format = 'YYYY-MM-DD', timezone = 'Europe/Tallinn') => {
  return timestamp
    ? moment(timestamp)
        .tz(timezone)
        .format(format)
    : null;
};

export const getStartMsOfCurrentMonth = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime() - date.getTimezoneOffset() * 60000;
};

export const getEndMsOfCurrentMonth = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setMonth(date.getMonth() + 1);
  return date.getTime() - date.getTimezoneOffset() * 60000 - 1;
};
