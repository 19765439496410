/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-06-05
 */

//types
export const SET_FORM_LOADER = 'SET FORM LOADER';

//creators
export const setFormLoader = (loaderValue, feature) => ({
  type: `${feature} ${SET_FORM_LOADER} ${loaderValue.toString().toUpperCase()}`,
  payload: loaderValue,
  meta: {feature},
});
