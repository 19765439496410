import React from 'react';
import styled from 'styled-components';
import {
  FOOTER_HEIGHT,
  FULL_WIDTH_CONTENT_1600px,
  MAX_WIDTH_1023,
  SCREEN_WIDTH_WHERE_FULL_CONTENT_IS_90perc,
} from '../../../config/LAYOUT';
import {withRouter} from 'react-router-dom';
import FooterLinks, {LINK_COLUMN_HEIGHT} from './FooterLinks';
import LogoContent from './LogoSectionContent';
import {Content as BasicContent} from '../../elements/styledElements';

const FooterBackground = styled.div`
  z-index: 99;
  display: flex;
  height: ${FOOTER_HEIGHT + 'px'};
  width: 100vw;
  background: var(--primary-dark);
  @media (${MAX_WIDTH_1023}) {
    height: ${FOOTER_HEIGHT + LINK_COLUMN_HEIGHT / 2 + 'px'};
  }
`;

const Content = styled(BasicContent)`
  width: 100%;
  text-align: left;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${FULL_WIDTH_CONTENT_1600px};
  margin: 0 auto;

  @media (max-width: ${SCREEN_WIDTH_WHERE_FULL_CONTENT_IS_90perc}) {
    max-width: 90%;
  }
`;

const Footer = ({location: {pathname}}) => {
  if (pathname.includes('statistics')) {
    return null;
  }

  return (
    <FooterBackground>
      <Content>
        <FlexDiv>
          <LogoContent />
          <FooterLinks />
        </FlexDiv>
      </Content>
    </FooterBackground>
  );
};

export default withRouter(Footer);
