const messages = {
  inputErrors: {
    invalidPhoneNumber: 'Please enter a valid phone number',
    invalidEmail: 'Please enter a valid e-mail address',
    missingClient: "Please enter client's name",
    missingName: 'Please enter a name or description',
    missingEmail: 'Please enter an e-mail address',
    missingUsername: 'Please enter a username',
    onlyWhitespaceInName: "Name can't consist of only whitespace characters",
    noAlphaNumericInName: 'Name should contain at least 1 alphanumeric character',
    existingName: 'Name already exists. Please choose a different name',
    noServices: 'Please choose at least one service',
  },
  passwordErrors: {
    missingCurrent: 'Please enter your current password',
    missingNew: 'Please enter a new password',
    missingNewRepeat: 'Please repeat the new password',
    missingGeneral: 'Please enter a password',
    missingRepeat: 'Please repeat the password',
    noLowerCase: 'Password should contain at least 1 lowercase letter',
    noNumbers: 'Password should contain at least 1 number',
    noUpperCase: 'Password should contain at least 1 uppercase letter',
    notLongEnough: 'Password should be at least 8 characters long',
    notMatching: "Passwords don't match",
    notUnique: 'Please choose a less common password',
  },
  verificationErrors: {
    failed: 'Verification failed',
  },
  apiErrors: {
    noVendorsAdded: 'Failed adding vendors to API key',
  },
};

export default messages;
