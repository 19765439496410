/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-02-06
 */

/**
 * React Loadable (async module loading) https://github.com/jamiebuilds/react-loadable
 */

import React from 'react';

import store from '../../store';
import {setLoader} from '../../store/actions/appLoader';
import Loadable from 'react-loadable';

let isPageLoading = false;

const feature = '[Page loader]';

const AppPageLoader = options => {
  return Loadable({
    loader: options.component,
    loading: props => {
      if (props.error && isPageLoading) {
        isPageLoading = false;
        store.dispatch(setLoader(false, feature));
      } else if (!isPageLoading) {
        isPageLoading = true;
        store.dispatch(setLoader(true, feature));
      }
      return null;
    },
    render(loaded, props) {
      let Component = loaded.default;
      setTimeout(() => {
        if (isPageLoading) {
          isPageLoading = false;
          store.dispatch(setLoader(false, feature));
        }
      }, 20);
      return <Component {...props} />;
    },
  });
};

export default AppPageLoader;
