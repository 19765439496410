import {SET_CLIENT_TRIAL} from '../actions/clientTrialPlan';
import {RESET_ALL} from '../constants';

export const initialState = {
  endDate: null,
  available: false,
};

export const clientTrialPlan = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CLIENT_TRIAL:
      return action.payload;
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
};
