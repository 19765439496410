import {SET_CONFIG} from '../actions/config';

const initialState = {};

export default function config(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONFIG:
      return action.payload;
    default:
      return state;
  }
}

export function selectAuthHeaderNamespace(state) {
  return state.config['AUTH_HEADER_NAMESPACE'];
}

export function selectApiDocsURL(state) {
  return state.config['API_DOCS_URL'];
}

export function selectEulaURL(state) {
  return state.config['EULA_URL'];
}

export function selectLandingPageUrl(state) {
  return state.config['LANDING_URL'];
}

export function selectDevelopersPageUrl(state) {
  return state.config['DEVELOPER_URL'];
}
