/**
 * @author: jevgeni.virves@reach-u.com
 * @since: 2018-05-28
 */
import {RESET_ALL} from '../constants';
import {SET_API_SERVICE_LIST, CLEAR_API_SERVICE_LIST} from '../actions/apiServiceList';

const initialState = [
  /*
  {
    id: "jgc_rest",
    name: "jgc_rest",
    methods: ["GET", "POST"],
    uris: "/jgc_rest/geocode",
    upstreamUrl: "http://bgm-geoservice-regio-ge-jgc-rest-server:8080/regio-ge-jgc-rest-server/geocode",
    status: null,
    statusTestUrl: "http://192.168.115.194/bgm-geoservice-api/jgc_rest/geocode?q=riia+24+tartu&gl=ee_aid&output=json2&srs=EPSG:3301&ac=true&maxcount=10&key=bla&rs=STD&apikey=bgm-index"
  },
  ...
   */
];

export default function apiServiceList(state = initialState, action = {}) {
  switch (action.type) {
    case SET_API_SERVICE_LIST: {
      return action.payload;
    }
    case CLEAR_API_SERVICE_LIST:
    case RESET_ALL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
